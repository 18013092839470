const EXPIRATION_TIME = 5 * 60 * 60 * 1000; // indexedDB中的数据定义5小时的过期时间

const IDB = {
  // 新增或者打开IndexDB
  openDB: function (dbName, storeName, version = 1) {
    return new Promise((resolve, reject) => {
      // 兼容浏览器
      const indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB;
      let db;
      // 打开数据库，如果没有则会创建
      const request = indexedDB.open(dbName, version);
      // 数据库打开成功回调
      request.onsuccess = function (event) {
        db = event.target.result;
        resolve(db);
      };
      request.onupgradeneeded = function (event) {
        db = event.target.result;
        let objectStore;
        objectStore = db.createObjectStore(storeName, {
          keyPath: "id", // 主键
          autoIncrement: true, // 自增
        });
      };
    });
  },
  addData: function (db, storeName, data) {
    const request = db
      .transaction([storeName], "readwrite") // 事务对象 指定表格名称和操作模式（"只读" 或 "读写"）
      .objectStore(storeName)
      .add(data);
  },
  cursorGetData: function (db, storeName) {
    return new Promise((resolve, reject) => {
      let list = new Map();
      const store = db
        .transaction(storeName, "readwrite")
        .objectStore(storeName);
      const request = store.openCursor(); // 指针对象
      request.onsuccess = function (e) {
        const cursor = e.target.result;
        if (cursor) {
          if (
            new Date().valueOf() - cursor.value.saveTime > EXPIRATION_TIME
          ) {
            cursor.delete();
          } else {
            list.set(cursor.value.key, cursor.value);
          }
          cursor.continue(); // 遍历
        } else {
          resolve(list);
        }
      };
      request.onerror = function (e) {
        reject(list);
      };
    });
  },
  deleteAllDate: function (db, storeName, doc) {
    return new Promise((resolve, reject) => {
      this.cursorGetData(db, storeName).then((map) => {
        const store = db
          .transaction(storeName, "readwrite")
          .objectStore(storeName);
        for (let [key, value] of map) {
          if (
            key.indexOf(`${doc.docId}_${doc.docVersion}_${doc.wmType}`) > -1
          ) {
            store.delete(key);
            store.delete(value.id);
          }
        }
        resolve();
      });
    });
  },
  getDataByIndex: function (db, storeName, indexName, indexValue) {
    const store = db.transaction(storeName, "readwrite").objectStore(storeName);
    var request = store.index(indexName).get(indexValue);
    request.onsuccess = function (e) {
      const result = e.target.result;
      return result;
    };
    return "";
  },
  updateDB: function (db, storeName, data) {
    const request = db
      .transaction([storeName], "readwrite")
      .objectStore(storeName)
      .put(data);
  },
  closeDB: function (db) {
    db.close();
  },
  deleteDBAll: function (dbName) {
    const deleteRequest = indexedDB.deleteDatabase(dbName);
  },
};

export default IDB;
