import "./zip-stream.js"
import streamSaver from "streamSaver"
import utils from "../../common/utils.js";
import SliceFileDownload from "./sliceFileDownload.js";
import SingleFileDownload from "./singleFileDownload.js";
import STATUSCODE from "../statusCode.js";
import EDMDownloadErrorCodes from "../errorCodes.js"
import { setXhrHeader } from "../service.js";

class ClientPackDownload {
  constructor(docs, instance, EdmUtils, ID, NetID) {
    this.instance = instance;
    this.EdmUtils = EdmUtils;
    this.ID = ID;
    this.NetID = NetID;
    this.list = [...docs];
    this.result = {};
    this.packDocs = new Map();
    this.nameSet = new Set()
    this.allSize = 0;
    this.downloadedSize = 0;
    this.writer = null;
    this.aborted = false;
    this.complete = false;
    this.runningCount = 0;
    this.maxRunningCount = 6;
    this.tempSize = 0;
    this.runningFile = new Set();
  }

  getFileName(doc, docSuffix) {
    let fileName;
    if (doc.customPath) { // 存在自定义路径
      doc.customPath = doc.customPath + "/";
      doc.customPath = doc.customPath.replace(/(\/)+/g, "/") // 标准化用户输入，将多斜杆、反斜杆统一转为斜杆

      if (doc.customPath.indexOf("/") === 0) {
        doc.customPath = doc.customPath.slice(1)
      }

      if (doc.fileName) { // 同时存在重命名
        const slashIndex = doc.fileName.lastIndexOf("/")
        if (slashIndex > -1) { // 重命名中有/情况
          fileName = doc.customPath + doc.fileName.slice(slashIndex + 1)
        } else {
          fileName = doc.customPath + doc.fileName
        }
      } else {
        fileName = doc.customPath + doc.docName
      }
    } else if (doc.fileName){ // 只存在重命名
      fileName = doc.fileName
    } else { // 既没有路径也没有重命名
      fileName = doc.docName
    }

    fileName = this.handleFileSuffix(fileName, docSuffix)
    fileName = this.handleDuplicateName(fileName)
    return fileName
  }

  // 文件后缀以原文档为准
  handleFileSuffix(name, docSuffix) {
    let newName;
    const dotIndex = name.lastIndexOf(".")
    if (dotIndex > -1) {
      newName = name.slice(0, dotIndex) + docSuffix
    } else { // 无后缀
      newName = name + docSuffix
    }
    return newName
  }

  // 文件名重名增加时间戳后缀
  handleDuplicateName(name) {
    let newName;
    if (this.nameSet.has(name)) {
      const dotIndex = name.lastIndexOf(".")
      const timeStamp = utils.getPreciseTimestamp()

      if (dotIndex > -1) {
        newName = name.slice(0, dotIndex) + "_" + timeStamp + name.slice(dotIndex)
      } else { // 无后缀
        newName = name + "_" + timeStamp
      }
    } else {
      this.nameSet.add(name)
      newName = name
    }
    return newName
  }

  // 获取查询接口参数
  getQueryDocsParams() {
    const ids = new Set();
    const params = {
      docInfoVO: {
        ids: [],
        docType: "all",
        docVersion: "",
      },
    };
    for (let i = 0; i < this.list.length; i++) {
      ids.add(this.list[i].docId);
    }
    params.docInfoVO.ids = Array.from(ids);
    return params;
  }

  // 查询所有文档信息
  queryDocsInfo() {
    return new Promise((resolve, reject) => {
      const EdmUtils = this.EdmUtils;
      const instance = this.instance;
      const url = instance.edmServiceDomain + instance.contextPath.querying;
      const xhr = utils.newXMLHttpRequest(instance.withCredentials);

      xhr.onreadystatechange = function (event) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            try {
              let res = JSON.parse(xhr.response);
              if (res.status === 200) {
                resolve(res.result.outDocQueryList);
              } else {
                reject({ status: res.status, message: res.message })
              }
            } catch (err) {
              reject(err);
            }
          } else {
            reject({ status: xhr.status, response: xhr.response })
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      setXhrHeader(xhr, EdmUtils, instance)

      const params = this.getQueryDocsParams();
      xhr.send(JSON.stringify(params));
    });
  }

  /**
   * 根据文档id、版本、附属文件类型获取文档信息
   * @param {Object} docsInfo 所有文档信息
   * @param {String} docId 待查询文档id
   * @param {String} docVer 待查询文档版本
   * @param {String} wmType 附属文档类型
   * @returns {Object} docInfo 单个文档信息
   */
  getDocInfoByVerAndwmType(docsInfo, docId, docVer, wmType) {
    if (utils.isEmpty(wmType)) { // 不传附属文档类型，默认为原文档, 统一为大写
      wmType = "src";
    }
    wmType = wmType.toUpperCase()

    const versInfo = docsInfo.filter((doc) => doc.id === docId)[0];

    if (utils.isEmpty(docVer)) { // 不传版本，默认为最新版, 统一为大写
      const sortedVers = versInfo.verInfo.sort((a, b)=> {
        return b.version.slice(1) - a.version.slice(1)
      })
      docVer = sortedVers[0].version
    }

    const typesInfo = versInfo.verInfo.filter(
      (doc) => utils.upperCompare(doc.version, docVer)
    )[0].docInfo;

    let docInfo;
    if (wmType === "IWM" || wmType === "WM") { // 处理iwm或者wm类型的文档，优先取同名附属文档，如果不存在，取另一种类型
      let docListByIwm = typesInfo.filter((doc) => {
        return doc.wmType ? utils.upperCompare(doc.wmType, "IWM") : false
      })[0];
      let docListByWm = typesInfo.filter((doc) => {
        return doc.wmType ? utils.upperCompare(doc.wmType, "WM") : false
      })[0];

      if (wmType === "IWM") {
        docInfo = docListByIwm ? docListByIwm : docListByWm
      } else {
        docInfo = docListByWm ? docListByWm : docListByIwm
      }
    } else {
      docInfo = typesInfo.filter((doc) => {
        if (doc.wmType) {
          return utils.upperCompare(doc.wmType, wmType)
        }
        return utils.upperCompare(doc.docType, wmType);
      })[0];
    }

    if (versInfo.downloadChunkGroupSize) { // 服务端并发配置
      docInfo.downloadChunkGroupSize = versInfo.downloadChunkGroupSize 
    }
    if (versInfo.downloadChunkSize) { // 服务端分片尺寸配置
      docInfo.downloadChunkSize = versInfo.downloadChunkSize
    }
    return docInfo;
  }

  /**
   * 处理附属文档为tpd类型的场景，tpd类型下载所有附属文档 
   * @param {Object} docsInfo 所有文档信息
   * @return {Boolean} 是否通过tpd校验，如果传了tpd类型，但是又没有附属文档，则不通过，否则通过
   */
  handleTpdDocs(docsInfo) {
    const newList = []
    for (let i = 0; i < this.list.length; i++) {
      const doc = this.list[i];
      if (doc.wmType && utils.upperCompare(doc.wmType, "TPD")) {
        const attachmentDocs = this.getAttachmentDocs(docsInfo, doc.docId, doc.docVersion)
        if (attachmentDocs.length === 0) {
          this.instance.fail(EDMDownloadErrorCodes.PACKAGE_DOWNLOAD_ERROR, this.list)
          return false
        } else {
          newList.push(...attachmentDocs)
        }
      } else {
        newList.push(doc)
      }
    }
    this.list = newList
    return true
  }

  /**
   * 获取所有附属文档
   * @param {Object} docsInfo 所有文档信息
   * @param {String} docId 待查询文档id
   * @param {String} docVer 待查询文档版本
   * @return {Array} attachmentDocsList 所有附属文档列表
   */
  getAttachmentDocs(docsInfo, docId, docVer) {
    const attachmentDocsList = []
    const versInfo = docsInfo.filter((doc) => doc.id === docId)[0];

    if (utils.isEmpty(docVer)) { // 不传版本，默认为最新版
      const sortedVers = versInfo.verInfo.sort((a, b)=> {
        return b.version.slice(1) - a.version.slice(1)
      })
      docVer = sortedVers[0].version
    }

    const typesInfo = versInfo.verInfo.filter((doc) => utils.upperCompare(doc.version, docVer))[0].docInfo;

    typesInfo.forEach((item)=> {
      if (item.wmType) {
        let newDoc = {
          appId: item.appId,
          docId: item.docId,
          docName: item.docName,
          docVersion: item.docVersion,
          fileName: item.fileName,
          wmType: item.wmType
        }
        attachmentDocsList.push(newDoc)
      }
    })
    return attachmentDocsList
  }

  /** 
   * @param {Object} docsInfo 下载文件信息
   */
  setDocInfo(docsInfo) {
    const docs = this.list;
    for (let i = 0; i < docs.length; i++) {
      let docInfo = this.getDocInfoByVerAndwmType(
        docsInfo,
        docs[i].docId,
        docs[i].docVersion,
        docs[i].wmType
      );
      let key = `${docs[i].docId}_${docs[i].docVersion}_${docs[i].wmType}`;
      this.packDocs.set(key, docInfo);
    }
  }

  // 失败处理，弹框用户确认失败文件是否重新下载 参数：ctrl 可读流控制器
  failRetry(ctrl) {
    const failTasks = Object.values(this.result).filter((i) => !i.success);
    let userConfirm = confirm(
      `${failTasks.length}个文件下载失败：\n${failTasks.map(doc => doc.docId).join()}\n失败文件是否重新下载？不重新下载将只打包已成功下载的文件。`
    );
    if (userConfirm) {
      failTasks.forEach((task) => {
        this.list.push(task);
      });
      this.seriesDownload(ctrl);
    } else {
      if (Object.values(this.result).length === failTasks.length) { // 一个文件都没下载成功，取消生成zip包，不取消会生成空文件
        this.writer.abort("全部文件下载失败取消生成zip包");
      }
      ctrl.close();
      this.updateProgress(this.allSize)
    }
  }

  // 根据文档大小创建不同类型的下载实例
  createDownloadFile(file) {
    if (file.fileSize > this.EdmUtils.CHUNK_LIMIT * 1024 * 1024) {
      return new SliceFileDownload(file, this);
    } else {
      return new SingleFileDownload(file, this);
    }
  }

  /**
   * 顺序并发下载，分片下载按分片并发数计并发数，单文件按1计并发数。存在下载任务，则下载。
   * 不存在下载任务，检查结果队列是否有失败任务，有失败任务，弹框用户确认失败任务是否重试。
   * 确认重试重新下载失败文件。
   * 确认不重试，只打包成功下载的文件。
   * @param {*} ctrl 流控制器
   */
  seriesDownload(ctrl) {
    if (this.runningCount >= this.maxRunningCount) return
    if (this.aborted) return

    const doc = this.list.shift();

    if (doc) {
      const key = `${doc.docId}_${doc.docVersion}_${doc.wmType}`;
      const fileInfo = this.packDocs.get(key)
      const file = this.createDownloadFile(fileInfo);
      const fileName = this.getFileName(doc, fileInfo.docSuffix);

      this.runningFile.add(file);

      file
        .download()
        .then((res) => {
          doc.success = true

          // 下载数据写入流
          ctrl.enqueue({
            name: fileName,
            size: res.size,
            stream: () => res.stream(),
          });

          // 更新进度
          this.downloadedSize += res.size
        })
        .catch((error) => {
          if (error.status === EDMDownloadErrorCodes.CACHE_FULL) { // 磁盘满了，取消下载，并抛出错误
            this.abort(true)
            this.instance.fail(EDMDownloadErrorCodes.CACHE_FULL, this.list)
            return
          }

          this.nameSet.delete(fileName) // 失败可能重试再次下载该文件，文件名集合需要删除，避免文件再次被重命名

          doc.success = false
          doc.error = error
        })
        .finally(() => {
          if (fileInfo.fileSize <= this.EdmUtils.CHUNK_LIMIT * 1024 * 1024) {
            this.runningCount--
          }
          this.runningFile.delete(file);
          this.updateProgress()
          this.result[key] = doc
          this.seriesDownload(ctrl);
        });

      // 避免速度太快，文件同名无法写入问题
      setTimeout(()=> {
        this.seriesDownload(ctrl);
      }, 1)
    } else {
      if (this.runningCount === 0) {
        const list = Object.values(this.result);
        const fail = list.filter((i) => !i.success);
        if (fail.length > 0) {
          this.failRetry(ctrl);
        } else {
          ctrl.close();
        }
      }
    }
  }

  /**
   * 设置文档全部大小
   */
  setAllSize() {
    for (let doc of this.list) {
      let key = `${doc.docId}_${doc.docVersion}_${doc.wmType}`
      let value = this.packDocs.get(key)
      this.allSize += value.fileSize
    }
  }

  /* 
   * 打包上限检测 依据chromium源码与blob storage设计文档
   * 1. https://chromium.googlesource.com/chromium/src/+/HEAD/storage/browser/blob/README.md   可写入blob storage最大可用为硬盘的10%
   * 2. https://docs.google.com/document/d/19QemRTdIxYaJ4gkHYf2WWBNPbpuZQDNMpUVf8dQxj4U/edit   正常模式浏览器可分配空间为磁盘的60%
   */
  async checkLimit() {
    try {
      const unit = 1024 * 1024 * 1024
      const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; //android终端
      let quota = (await navigator.storage.estimate()).quota
      let incognitoMode = quota < 2 * unit
      if (incognitoMode) { // 无痕模式，blob存在内存中，上限2G。安卓机型为存储空间/100，由于无api获取存储空间，故也使用2G估算
        if (this.allSize > (2 * unit)) {
          return false
        }
        return true
      } else {
        if (isAndroid) { // 磁盘的6%
          let browerStorageLimit = quota / 0.6 * 0.06
          if (this.allSize > browerStorageLimit) {
            return false
          }
          return true
        } else { // 磁盘的10%
          let browerStorageLimit = quota / 0.6 * 0.1
          if (this.allSize > browerStorageLimit) {
            return false
          }
          return true
        }
      }
    } catch (err) { // 浏览器类型、版本不支持该API，跳过检测
      return true
    }
  }

  // 跨应用、已销毁，查询会返回空，已删除docStatus值为10
  checkException(docsInfo) {
    let tip = `Package download error:\n`;
    let exceptionFlag = false;
    const emptyList = []
    const deleteList = []
    for (let i = 0; i < docsInfo.length; i++) {
      let docInfo = docsInfo[i]
      let versInfo = docInfo.verInfo
      let docId = docInfo.id
      if (versInfo.length === 0) {
        emptyList.push(docId)
      } else {
        let downloadDoc = this.list.filter(doc => docId === doc.docId)[0]
        let docInfo = this.getDocInfoByVerAndwmType(docsInfo, docId, downloadDoc.docVersion, downloadDoc.wmType)
        if (docInfo && docInfo.docStatus === 10) {
          deleteList.push(docId)
        }
      }
    }

    if (emptyList.length > 0) {
      tip += `${emptyList.join(",")} have been destroy or do not exist or do not have the share permission.\n`
      exceptionFlag = true
    }

    if (deleteList.length > 0) {
      tip += `${deleteList.join(",")} have been deleted.\n`
      exceptionFlag = true
    }

    if (exceptionFlag) {
      this.instance.fail({ status: 12082, message: tip }, this.list)
      return true
    }

    return false
  }

  // 下载主流程
  download(retry = 0) {
    const that = this
    this.queryDocsInfo()
      .then(async(docsInfo) => {
        let tpdCheck = this.handleTpdDocs(docsInfo)
        if (!tpdCheck) {
          this.instance.handleProgress(this.allSize)
          this.updateStatus(STATUSCODE.FAILED)
          return
        }

        let existException = this.checkException(docsInfo)
        if (existException) {
          this.instance.handleProgress(this.allSize)
          this.updateStatus(STATUSCODE.FAILED)
          return 
        }

        this.setDocInfo(docsInfo);
        this.setAllSize();

        let limitRes = await this.checkLimit()
        if (!limitRes) {
          this.instance.fail(EDMDownloadErrorCodes.CLIENTPACK_SIZE_LIMIT, this.list)
          return
        }

        const zipFileName = utils.getPreciseTimestamp() + ".zip";
        let zipFileOutputStream = streamSaver.createWriteStream(zipFileName);
        let writer = zipFileOutputStream.getWriter();
        this.writer = writer;
        this.instance.writer = writer; // writer挂载到下载实例上，让其调用abort时可取消下载

        const readableZipStream = new ZIP({
          async start(ctrl) {
            that.seriesDownload(ctrl);
          }
        });

        let reader = readableZipStream.getReader();

        const pump = () => {
          return reader.read().then((readRes) => {
            if (readRes.done) {
              writer.close();
              this.finish()
            } else {
              writer
                .write(readRes.value)
                .then(pump)
                .catch((err) => {});
            }
          });
        };

        pump();
        this.updateStatus(STATUSCODE.DOWNLOADING)
      }).catch((err) => {
        if (err.status === 0) {
          if (retry < this.EdmUtils.REPLAY_LIMIT) {
            retry++
            this.download(retry)
          } else {
            this.instance.fail(EDMDownloadErrorCodes.NET_EXCEPTION, this.list)
          }
        } else if (err.status === 403) {
          try {
            let response = JSON.parse(err.response)
            if (response.message !== undefined) {
              this.instance.fail({ status: response.status, message: response.message }, this.list)
            } else {
              this.instance.fail(EDMDownloadErrorCodes.NO_PERMISSION, this.list)
            }
          } catch(err) {
            this.instance.fail(EDMDownloadErrorCodes.NO_PERMISSION, this.list)
          }
        } else if (err.message) {
          this.instance.fail(err, this.list)
        } else {
          this.instance.fail(EDMDownloadErrorCodes.DOWNLOAD_ERROR, this.list)
        }
      });
  }

  updateStatus(status) {
    this.EdmUtils.updateDocStatus(this.ID, this.NetID, status.code, status.message);
  }

  updateProgress(data) {
    if (this.aborted) { // 取消后，进度置为100
      data = this.allSize
    }

    let percent = 0

    if (this.allSize === 0) { // 处理所有文档均为0kb的场景
      percent = 1
    } else {
      if (data) { // 取消或者不重试场景，直接设置data
        percent = data / this.allSize
      } else { // 其他场景
        let tempSize = 0
        if (this.runningFile.size > 0) {
          for (let file of this.runningFile) {
            tempSize += file.loadSize
          }
        }
        tempSize += this.downloadedSize

        if (this.tempSize > tempSize) {
          return
        } else {
          this.tempSize = tempSize
        }
        percent = tempSize / this.allSize
        if (percent > 1) {
          percent = 1
        }
      }
    }
    percent = (percent * 100).toFixed(2)
    this.instance.handleProgress(percent)
  }

  abort(auto) {
    if (this.complete || this.aborted) return // 下载完成或者已经取消，取消无效
    if (this.writer) { // 用户取消时可能第一个文件还没开始写，此时writer不存在
      this.writer.abort(auto ? "失败自动取消下载" : "用户取消下载")
    }
    if (this.runningFile.size > 0) {
      for (let file of this.runningFile) {
        file.abort()
      }
    }
    this.aborted = true
    this.updateProgress(this.allSize)
    this.updateStatus(STATUSCODE.CANCEL)
  }

  finish() {
    this.updateStatus(STATUSCODE.COMPLETE)
    this.complete = true
    if (this.instance.finish) {
      this.instance.finish(this.instance.cacheDocuments)
    }
  }
}

function clientPackDownload(docs, instance, EdmUtils, ID, NetID) {
  const downloadInstance = new ClientPackDownload(docs, instance, EdmUtils, ID, NetID);
  instance.cacheDocuments[NetID].xhr = {
    abort: downloadInstance.abort.bind(downloadInstance)
  }

  downloadInstance.download();
}

export default clientPackDownload;