export function setXhrHeader(xhr, EdmUtils, instance, doc) {
  xhr.setRequestHeader(EdmUtils.HEADERS.APP_ID, instance.appId);
  xhr.setRequestHeader(
    EdmUtils.HEADERS.TRACE_ID,
    instance.requestTrace + Date.now()
  );
  if (instance.enterpriseDocumentToken.indexOf("Basic") === 0) {
    xhr.setRequestHeader(
      EdmUtils.HEADERS.OLD_TOKEN,
      instance.enterpriseDocumentToken
    );
    xhr.setRequestHeader("x-csrf-token", instance.xCsrfToken);
    xhr.setRequestHeader(
      EdmUtils.HEADERS.API_ALIAS,
      EdmUtils.apiParams.apiAliasSingle
    );
  } else {
    xhr.setRequestHeader(
      EdmUtils.HEADERS.TOKEN,
      instance.enterpriseDocumentToken
    );
    xhr.setRequestHeader("x-csrf-token", instance.xCsrfToken);
  }

  if (doc && doc.fileName && typeof doc.fileName === "string") {
    if (!instance.checkPoint(doc.fileName)) {
      return;
    }
    xhr.setRequestHeader("fileName", encodeURIComponent(doc.fileName));
  }
}
