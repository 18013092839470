const STATUSCODE = {
  CANCEL: {
    code: 0,
    message: "The download is canceled,下载被取消",
  },
  READY: {
    code: 101,
    message: "Ready to download;准备下载中",
  },
  DOWNLOADING: {
    code: 102,
    message: "Downloading;正在下载中",
  },
  CHUNKDOWNLOADING: {
    code: 106,
    message: "Block downloading;分块下载中",
  },
  MERGE: {
    code: 107,
    message: "In Merge Files;合并文件中",
  },
  COMPLETE: {
    code: 200,
    message: "Download complete;下载完成",
  },
  FAILED: {
    code: 500,
    message: "Download failed;下载失败",
  },
};

export default STATUSCODE
