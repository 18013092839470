import "../common/browserPolyfill.js"
import streamSaver from "streamSaver"
import IDB from "./indexedDB.js"
import EDMDownloadErrorCodes from "./errorCodes.js"
import utils from "../common/utils.js"
import clientPackDownload from "./clientPackDownload/clientPackDownload.js"

//  实例对象 Instance Object
var EdmInObj = {}

//  EDM公共方法
var EdmUtils = {
  NetworkSpeedObject: {},
  TemnetworkSpeed: 0,
  Firstflag: true,
  clientPackFlag: undefined,
  NetSpeedInterval: null,
  envs: {
    cloudblue: {
      prod: 'http://apig.heds.hihonor.com/api/edm',
      beta: 'http://hicuat.huawei.com/edm'
    },
    internal: {
      prod: `${utils.getProtocol()}//edm3.huawei.com/edm`,
      beta: `${utils.getProtocol()}//edm3-beta.huawei.com/edm`
    },
    apigw: {
      beta: 'http://edm3-dmz-beta.his.huawei.com/edm',
      prod: 'http://edm3-dmz.his.huawei.com/edm/'
    }
  },
  isIE: !!window.ActiveXObject || 'ActiveXObject' in window,
  EMPTY: '',
  TOKEN_REPLAY_LIMIT: 50,
  REPLAY_LIMIT: 3,
  CHUNK_LIMIT: 50, // 定义多少M开始分片
  CHUNK_ATOM: 8, // 分片的每片大小
  CHUNK_BATCH_LIMIT: 4, // 每个批次的并发数量
  HEADERS: {
    APP_ID: 'x-app-id',
    TRACE_ID: 'x-trace-id',
    TOKEN: 'EDM-Authorization',
    CONTENT_TYPE: 'Content-Type',
    API_ALIAS: 'apiAlias',
    OLD_TOKEN: 'Authorization'
  },
  TOKEN_PAMETERS: {
    action: 'edmTest',
    serverType: 'download'
  },
  apiParams: {
    apiAliasSingle: 'EDM3.edm.projects._project_id_.documents._doc_id_.get',
    apiAliasBatch: 'EDM3.edm.projects._project_id_.packages.documents.post'
  },
  // 终止下载
  stopDonwload: function (ID, NetID) {
    if (EdmInObj[ID].cacheDocuments[NetID].abort === true) {
      EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
      return
    }
  },
  // 判断实例化是否为空
  isEmptyOp: function (ID) {
    if (utils.isEmpty(EdmInObj[ID])) {
      EdmInObj[ID].unavailableInstance = true
      EdmInObj[ID] = {}
      return true
    } else {
      EdmInObj[ID].unavailableInstance = false
      return false
    }
  },
  // 判断用户传入的 APPId 是否为空
  isEmptyAppId: function (ID) {
    if (utils.isEmpty(EdmInObj[ID].appId)) {
      EdmInObj[ID].unavailableInstance = true
      EdmInObj[ID].fail(EDMDownloadErrorCodes.EMPTY_APP_ID)
      return true
    } else {
      return false
    }
  },
  // 设置下载失败回调
  setFail: function (ID) {
    if (utils.isEmpty(EdmInObj[ID].fail)) {
      EdmInObj[ID].fail = function (data) {
        return data
      }
    }
  },
  // 设置下载网速回调
  setNetworkSpeed: function (ID) {
    EdmInObj[ID].networkSpeed = EdmInObj[ID].networkSpeed
    if (
      EdmInObj[ID].networkSpeed === null ||
      EdmInObj[ID].networkSpeed === undefined ||
      typeof EdmInObj[ID].networkSpeed !== 'function'
    ) {
      EdmInObj[ID].networkSpeed = function (data) {
        return data
      }
    }
  },
  // 设置下载状态回调
  setStatusCallback: function (ID) {
    // 状态通知
    EdmInObj[ID].downloadStatus = EdmInObj[ID].status
    if (utils.isEmpty(EdmInObj[ID].downloadStatus)) {
      EdmInObj[ID].downloadStatus = function (data) {
        return data
      }
    }
  },
  // 设置文件下载接口
  setServiceDomain: function (ID) {
    EdmInObj[ID].edmServiceDomain = EdmInObj[ID].endPoint
    if (utils.isEmpty(EdmInObj[ID].edmServiceDomain)) {
      if (
        utils.isEmpty(EdmInObj[ID].env) ||
        utils.isEmpty(EdmUtils.envs[EdmInObj[ID].network][EdmInObj[ID].env])
      ) {
        EdmInObj[ID].edmServiceDomain = EdmUtils.envs[EdmInObj[ID].network].beta
      } else {
        EdmInObj[ID].edmServiceDomain = EdmUtils.envs[EdmInObj[ID].network][EdmInObj[ID].env]
      }
    }

    EdmUtils.getChunkAtom(EdmInObj[ID].chunkAtom)
    EdmInObj[ID].contextPath = {
      single:
        '/projects/{appId}/documents/{docId}?docVersion={docVersion}' + '&wmType={wmType}&decryptKey={decryptKey}',
      zip: '/projects/{appId}/packages/documents?type=package',
      querying: '/projects/{appId}/tasks?action=query',
      token: '/projects/{appId}/tokens',
      asyncPackages: '/v1/projects/{appId}/async/packages?type=package',
      clientPackSwitch: "/projects/{appId}/client/batchDocDownload/enable"
    }
    EdmInObj[ID].tokenMethod = EdmInObj[ID].tokenMethod || 'GET'
    EdmInObj[ID].https = !!EdmInObj[ID].https
    EdmInObj[ID].appId = EdmInObj[ID].appId
    EdmInObj[ID].breakWhenError = !!EdmInObj[ID].breakWhenError
    EdmInObj[ID].uuid = EdmInObj[ID].uuid
    EdmInObj[ID].enterpriseDocumentToken = ''
    EdmInObj[ID].requestTrace = ''
    EdmInObj[ID].tokenReplayCount = 0
    EdmInObj[ID].ReplayCount = 0
    EdmInObj[ID].netExceptionFlag = false
    EdmInObj[ID].apigw = EdmInObj[ID].apigw || null
    EdmInObj[ID].sdkDomainHeader = EdmInObj[ID].sdkDomainHeader || null
    EdmInObj[ID].pushAppMsg = EdmInObj[ID].pushAppMsg || null
    EdmInObj[ID].asyncPackages = EdmInObj[ID].asyncPackages || false
    EdmInObj[ID].downloadType = EdmInObj[ID].downloadType || 'default'
    for (var contextKey in EdmInObj[ID].contextPath) {
      EdmInObj[ID].contextPath[contextKey] = EdmInObj[ID].contextPath[contextKey].replace('{appId}', EdmInObj[ID].appId)
    }
    if (EdmInObj[ID].https) {
      EdmInObj[ID].edmServiceDomain = EdmInObj[ID].edmServiceDomain.replace('http://', 'https://')
    }
    // 注意篮版没有 packages ,修改脚本
    streamSaver.mitm =
      EdmInObj[ID].edmServiceDomain.slice(0, EdmInObj[ID].edmServiceDomain.length - 3) +
      `edm3client/${EdmInObj[ID].network === 'internal' ? 'packages/' : ''}webview/commons/js/mitm.html`
    if (streamSaver.mitm.indexOf('http://') !== -1) {
      streamSaver.mitm = streamSaver.mitm.replace('http://', 'https://')
    }
    EdmInObj[ID].tokenDomain = EdmInObj[ID].sdkDomain || EdmInObj[ID].edmServiceDomain + EdmInObj[ID].contextPath.token
  },
  // 设置响应状态错误时的报错
  setResponseErrorResolve: function (ID) {
    EdmInObj[ID].responseErrorResolve = function (docs, xhr, recall) {
      if (xhr.status === 401) {
        if (EdmInObj[ID].tokenReplayCount >= EdmUtils.TOKEN_REPLAY_LIMIT) {
          EdmInObj[ID].fail(EDMDownloadErrorCodes.TOKEN_INVALID, docs)
          return EdmUtils.EMPTY
        } else {
          EdmInObj[ID].tokenReplayCount = EdmInObj[ID].tokenReplayCount + 1
          EdmInObj[ID].requestEdmToken(true, docs).then((tokenFlag) => {
            if (tokenFlag) {
              return recall()
            } else {
              return EdmUtils.EMPTY
            }
          })
        }
      } else if (xhr.status === 403) {
        let response = xhr.response
        if (xhr.responseType === 'blob') {
          const reader = new FileReader()
          reader.readAsText(xhr.response, 'utf-8')
          reader.onload = (e) => {
            response = JSON.parse(e.target.result)
            EdmInObj[ID].fail({ status: response.status, message: response.message }, docs)
          }
        } else {
          if (typeof response === 'string') {
            response = JSON.parse(response)
          }
          if (response.message !== undefined) {
            EdmInObj[ID].fail({ status: response.status, message: response.message }, docs)
          } else {
            EdmInObj[ID].fail(EDMDownloadErrorCodes.NO_PERMISSION, docs)
          }
        }
        return EdmUtils.EMPTY
      } else if (xhr.status === 12079) {
        EdmInObj[ID].fail(EDMDownloadErrorCodes.KIA_SCANNING, docs)
        return EdmUtils.EMPTY
      } else {
        EdmInObj[ID].fail(EDMDownloadErrorCodes.DOWNLOAD_ERROR, docs)
        return EdmUtils.EMPTY
      }
    }
  },
  // 获取 EDMToken 中需要添加的参数
  setRequestEdmTokenParams: function (ID, queryTokenParameter) {
    var params = {
      appId: EdmInObj[ID].appId,
      serverType: EdmUtils.TOKEN_PAMETERS.serverType
    }
    if (queryTokenParameter) {
      if (EdmInObj[ID].tokenMethod === 'POST' && EdmInObj[ID].isBatchDocid === true) {
        var docList = []
        for (let i = 0; i < queryTokenParameter.length; i++) {
          docList.push({
            docId: queryTokenParameter[i].docId,
            wmType: queryTokenParameter[i].wmType || '',
            docVersion: queryTokenParameter[i].docVersion
          })
        }
        params.docList = docList
        if (EdmInObj[ID].userPermissionsExt) {
          params.userPermissionsExt = EdmInObj[ID].userPermissionsExt
        }
      } else {
        params.docId = queryTokenParameter[0].docId
        params.docVersion = queryTokenParameter[0].docVersion
        params.wmType = queryTokenParameter[0].wmType || ''
        if (EdmInObj[ID].userPermissionsExt) {
          params.userPermissionsExt = JSON.stringify(EdmInObj[ID].userPermissionsExt)
        }
      }
      params.decryptKey = queryTokenParameter[0].decryptKey || ''

      params.paraSah256 = 'edm'
    }
    let DomainParame = EdmInObj[ID].sdkDomainParame || {}
    if (Object.prototype.toString(DomainParame) !== '[object Object]') {
      DomainParame = {}
    }
    for (let item in DomainParame) {
      if (!params[item]) {
        params[item] = DomainParame[item]
      }
    }
    return params
  },
  // 解决 token 自定义参数与 url 参数重复问题
  downloadFilterDupFields: function (url) {
    let paramsObj = utils.parseUrlQuery(url)
    let downloadUniqueParams = {}
    for (let item in paramsObj) {
      if (!downloadUniqueParams[item]) {
        if (typeof paramsObj[item] === 'object' && paramsObj[item].length > 1) {
          downloadUniqueParams[item] = paramsObj[item][0]
        } else {
          downloadUniqueParams[item] = paramsObj[item]
        }
      }
    }
    url = url.indexOf('?') !== -1 ? url.substring(0, url.indexOf('?')) : url
    return utils.uriParamFormat(url, downloadUniqueParams)
  },
  // 判断 EDMToken 是否是以 GET 方式获取的
  serRequestEdmTokenIfGEt: function (ID, u, params) {
    var url = u
    if (EdmInObj[ID].tokenMethod === 'GET') {
      url = utils.uriParamFormat(url, params)
    }
    return EdmUtils.downloadFilterDupFields(url)
  },
  // 发起 Ajax 请求，获取 EDMToken
  setRequestEdmToken: function (ID) {
    EdmInObj[ID].requestEdmToken = function (refresh, queryTokenParameter) {
      return new Promise((resolve, reject) => {
        if (EdmInObj[ID].unavailableInstance) {
          return resolve(false)
        }
        if (EdmInObj[ID].generateToken) {
          EdmInObj[ID].enterpriseDocumentToken = EdmInObj[ID].generateToken()
          EdmInObj[ID].requestTrace = 'edm3.js.sdk.' + new Date().getTime()
          return resolve(true)
        }
        if (!refresh && !utils.isEmpty(EdmInObj[ID].enterpriseDocumentToken)) {
          return resolve(true)
        }
        if (refresh) {
          EdmInObj[ID].enterpriseDocumentToken = ''
          EdmInObj[ID].requestTrace = ''
        }
        var xhr = utils.newXMLHttpRequest(true)
        var url = EdmInObj[ID].tokenDomain
        var params = EdmUtils.setRequestEdmTokenParams(ID, queryTokenParameter)
        url = EdmUtils.serRequestEdmTokenIfGEt(ID, url, params)
        xhr.open(EdmInObj[ID].tokenMethod, url, true)
        if (EdmInObj[ID].sdkDomainHeader) {
          var valueArr = utils.getValueArr(EdmInObj[ID].sdkDomainHeader)
          for (var i = 0; i < valueArr.length; i++) {
            xhr.setRequestHeader(valueArr[i], EdmInObj[ID].sdkDomainHeader[valueArr[i]])
          }
        }
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              var response = utils.isEmpty(xhr.response) ? {} : JSON.parse(xhr.response)
              if (response && response.status === 200) {
                EdmInObj[ID].config = response.result.config
                EdmInObj[ID].enterpriseDocumentToken = response.result.edmToken
                EdmInObj[ID].requestTrace = response.result.traceId || ''
                return resolve(true)
              } else if (response.edmToken) {
                EdmInObj[ID].enterpriseDocumentToken = response.edmToken
                EdmInObj[ID].requestTrace = response.traceId || ''
                return resolve(true)
              } else {
                response = response || {}
                var errorStatus = response.status || 500
                var errorMessage = response.message || EDMDownloadErrorCodes.LOAD_TOKEN_ERROR.message
                EdmInObj[ID].fail({
                  status: errorStatus,
                  message: errorMessage
                })
                return resolve(false)
              }
            } else {
              EdmInObj[ID].fail({
                status: xhr.status,
                message: EDMDownloadErrorCodes.LOAD_TOKEN_ERROR.message
              })
              return resolve(false)
            }
          }
        }
        if (EdmInObj[ID].tokenMethod === 'GET') {
          xhr.send()
        } else {
          xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
          xhr.send(JSON.stringify(utils.setRequestEdmTokenQueryBody(url, params)))
        }
      })
    }
  },
  // 对用户传入的文档信息的校验
  setVerify: function (ID) {
    EdmInObj[ID].verify = function (docs) {
      if (utils.isEmpty(docs)) {
        EdmInObj[ID].fail(EDMDownloadErrorCodes.DOC_EMPTY)
        return Promise.resolve(false)
      }
      for (var i = 0; i < docs.length; i++) {
        var doc = docs[i]
        if (utils.isEmpty(doc.docVersion)) {
          doc.docVersion = ''
        }
        if (docs.length === 1) {
          // 单个文档下载时处理iwm附属文档类型，批量下载不需要处理
          const wmType = doc.wmType
          if (wmType && wmType.toLowerCase() === 'iwm') {
            doc.wmType = 'all'
            doc.iwmType = true
          }
        }
      }
      return EdmInObj[ID].requestEdmToken(true, docs)
    }
  },
  // 下载进度回调
  setHandleProgress: function (ID) {
    EdmInObj[ID].handleProgress = EdmInObj[ID].progress
    if (
      EdmInObj[ID].handleProgress === null ||
      EdmInObj[ID].handleProgress === undefined ||
      typeof EdmInObj[ID].handleProgress !== 'function'
    ) {
      EdmInObj[ID].handleProgress = function (data) {
        return data
      }
    }
  },
  // 多文件下载时获取每个文件的信息
  getBatchDownloadData: function (docs, ID) {
    var data = {
      type: 'package',
      downloadTOs: [],
      attachdownloadTOs: [],
      transformType: 'sync',
      isZip: 'true'
    }
    for (var index = 0; index < docs.length; index++) {
      if (docs[index].wmType) {
        data.attachdownloadTOs.push(docs[index])
      } else {
        data.downloadTOs.push(docs[index])
      }
    }
    if (EdmInObj[ID].userId) {
      data.userId = EdmInObj[ID].userId
    }
    return data
  },
  // 跨站打包下载参数
  getAsyncDownloadPackagesData: function (docs, ID) {
    var data = {
      type: 'package',
      downloadTOs: [],
      attachdownloadTOs: []
    }
    for (var index = 0; index < docs.length; index++) {
      if (docs[index].wmType) {
        data.attachdownloadTOs.push(docs[index])
      } else {
        data.downloadTOs.push(docs[index])
      }
    }
    if (EdmInObj[ID].pushAppMsg) {
      data.pushAppMsg = EdmInObj[ID].pushAppMsg
    }
    if (EdmInObj[ID].wmType) {
      data.wmType = EdmInObj[ID].wmType
    }
    if (EdmInObj[ID].userId) {
      data.userId = EdmInObj[ID].userId
    }
    return data
  },
  // 多文件下载时获取多文件下载的 url
  getBatchDownloadUrl: function (ID, type) {
    var url = EdmInObj[ID].edmServiceDomain + EdmInObj[ID].contextPath[type]
    if (EdmInObj[ID].userId) {
      var params = {}
      params.userId = EdmInObj[ID].userId
      url = utils.uriParamFormat(url, params)
    }
    return url
  },
  // 计算多文件下载进度
  setBatchProgress: function (xhr, ID, NetID) {
    var NetworkFlag = true
    var NetworkSpeed = setInterval(() => {
      NetworkFlag = true
    }, 1000)
    xhr.addEventListener(
      'progress',
      function (event) {
        var total = xhr.getResponseHeader('Content-Size')
        var percent = ((event.loaded / total) * 100).toFixed(2)
        if (percent >= 100) {
          percent = (90).toFixed(2)
        }
        var NetBatchIDObj = {
          total: total,
          loaded: event.loaded
        }
        if (NetworkFlag === true) {
          EdmUtils.CalculateNetworkSpeed(ID, NetID, NetBatchIDObj)
          NetworkFlag = false
        }
        if (event.loaded === event.total) {
          clearInterval(NetworkSpeed)
        }
        EdmInObj[ID].handleProgress(percent) // 回调进度
      },
      false
    )
  },
  // 创建下载链接
  setCompDownload: function (xhr, ID, type) {
    var blob = new Blob([xhr.response], { type: 'appliction/zip' })
    var responseHeaders = xhr.getResponseHeader('Content-Disposition')
    var filename = EdmUtils.responseFileName(responseHeaders, 'UnKnow Name.zip')
    var URL = window.URL || window.webkitURL || window.mozURL
    if (EdmUtils.isIE && typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      var link = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = link
      a.download = filename
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    if (type === 'Batch') {
      EdmInObj[ID].handleProgress((100).toFixed(2)) // 回调进度
    }
  },
  setAsyncDownloadFail: function (ID) {
    EdmInObj[ID].asyncDownloadFail = function (shortLinkXhr) {
      var failFlag = shortLinkXhr.getResponseHeader('Content-Type') !== 'application/json;charset=UTF-8'
      if (shortLinkXhr.readyState === 4 && shortLinkXhr.status === 200 && !failFlag && shortLinkXhr.response !== null) {
        const reader = new FileReader()
        reader.readAsText(shortLinkXhr.response, 'utf-8')
        reader.onload = (e) => {
          EdmInObj[ID].fail(JSON.parse(e.target.result))
        }
        return false
      }
      return true
    }
  },
  setAsyncDownloadLink: function (ID) {
    EdmInObj[ID].asyncDownloadLink = function (docs, result, NetID, cnt = 0) {
      EdmUtils.asyncIndex++
      if (EdmUtils.asyncIndex >= EdmUtils.asyncLinkLength) {
        return
      }
      let url = result[EdmUtils.asyncIndex].downloadLink
      EdmInObj[ID].cacheDocuments[NetID].xhr = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
      let shortLinkXhr = EdmInObj[ID].cacheDocuments[NetID].xhr
      shortLinkXhr.open('GET', url, true)
      shortLinkXhr.responseType = 'blob'
      EdmUtils.setBatchProgress(shortLinkXhr, ID, NetID)
      shortLinkXhr.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
      shortLinkXhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      EdmUtils.updateDocStatus(ID, NetID, 102, 'Downloading;正在下载中')
      shortLinkXhr.onreadystatechange = function () {
        if (!EdmInObj[ID].asyncDownloadFail(shortLinkXhr)) {
          EdmInObj[ID].asyncDownloadLink(docs, result, NetID)
          return false
        }
        if (shortLinkXhr.readyState === 4 && shortLinkXhr.status === 200) {
          EdmUtils.updateDocStatus(ID, NetID, 200, 'Download complete;下载完成')
          EdmUtils.asyncCntSuccess++
          if (EdmUtils.asyncCntSuccess === result.length) {
            EdmUtils.DownloadFinish(ID)
          }
          EdmUtils.clearNetworkSpeed(ID)
          EdmUtils.resolveDownloadResponse(
            shortLinkXhr.response,
            function () {
              EdmUtils.setCompDownload(shortLinkXhr, ID, 'Batch')
            },
            function (failData) {
              EdmInObj[ID].fail(failData, docs)
            }
          )
          EdmInObj[ID].asyncDownloadLink(docs, result, NetID)
          return true
        } else if (shortLinkXhr.readyState === 4 && shortLinkXhr.status === 0) {
          if (EdmInObj[ID].cacheDocuments[NetID].abort === true) {
            EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
            return true
          }
          if (cnt < EdmUtils.REPLAY_LIMIT) {
            cnt ++
            EdmInObj[ID].asyncDownloadLink(docs, result, NetID, cnt)
          } else {
            EdmUtils.clearNetworkSpeed(ID)
          }
          return false
        } else if (shortLinkXhr.readyState === 4) {
          EdmInObj[ID].responseErrorResolve(docs, shortLinkXhr, function () {
            EdmInObj[ID].asyncDownloadLink(docs, NetID)
          })
          EdmInObj[ID].asyncDownloadLink(docs, result, NetID)
          EdmUtils.clearNetworkSpeed(ID)
          return false
        }
      }
      shortLinkXhr.send()
    }
  },
  // 跨站打包下载
  setAsyncDownloadPackages: function (ID) {
    EdmInObj[ID].asyncDownloadPackages = function (docs, NetID, cnt = 0) {
      var data = EdmUtils.getAsyncDownloadPackagesData(docs, ID)
      var url = EdmUtils.getBatchDownloadUrl(ID, 'asyncPackages')
      EdmInObj[ID].cacheDocuments[NetID].xhr = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
      var PackagesXhr = EdmInObj[ID].cacheDocuments[NetID].xhr
      PackagesXhr.open('POST', url, true)
      PackagesXhr.setRequestHeader(EdmUtils.HEADERS.CONTENT_TYPE, 'application/json;charset=UTF-8')
      PackagesXhr.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
      PackagesXhr.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
      if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
        PackagesXhr.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        PackagesXhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
        PackagesXhr.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasBatch)
      } else {
        PackagesXhr.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        PackagesXhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      }
      PackagesXhr.onreadystatechange = function () {
        if (PackagesXhr.readyState === 4 && PackagesXhr.status === 200) {
          if (JSON.parse(PackagesXhr.response).status !== 200) {
            let packagesFailObj = {
              status: JSON.parse(PackagesXhr.response).status,
              message: JSON.parse(PackagesXhr.response).message
            }
            EdmInObj[ID].fail(packagesFailObj, docs)
          } else {
            let result = JSON.parse(PackagesXhr.response).result
            EdmUtils.asyncLinkLength = result.length
            EdmInObj[ID].asyncDownloadLink(docs, result, NetID)
          }
          return true
        } else if (PackagesXhr.readyState === 4 && PackagesXhr.status === 0) {
          if (EdmInObj[ID].cacheDocuments[NetID].abort === true) {
            EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
            return true
          }
          if (cnt < EdmUtils.REPLAY_LIMIT) {
            cnt ++
            EdmInObj[ID].asyncDownloadPackages(docs, NetId, cnt)
          } else {
            EdmUtils.clearNetworkSpeed(ID)
          }
          return false
        } else if (PackagesXhr.readyState === 4) {
          EdmInObj[ID].responseErrorResolve(docs, PackagesXhr, function () {
            EdmInObj[ID].asyncDownloadPackages(docs, NetId)
          })
          EdmUtils.clearNetworkSpeed(ID)
          return false
        }
      }
      PackagesXhr.send(JSON.stringify(data))
    }
  },
  // 发送 ajax 请求，进行多文件下载
  setBatchDownload: function (ID) {
    EdmInObj[ID].batchDownload = function (docs, NetID, cnt = 0) {
      var data = EdmUtils.getBatchDownloadData(docs, ID)
      var url = EdmUtils.getBatchDownloadUrl(ID, 'zip')
      EdmInObj[ID].cacheDocuments[NetID].xhr = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
      var xhr = EdmInObj[ID].cacheDocuments[NetID].xhr
      xhr.open('POST', url, true)
      xhr.responseType = 'blob'
      EdmUtils.setBatchProgress(xhr, ID, NetID)
      xhr.setRequestHeader(EdmUtils.HEADERS.CONTENT_TYPE, 'application/json;charset=UTF-8')
      xhr.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
      xhr.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
      if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
        xhr.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
        xhr.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasBatch)
      } else {
        xhr.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      }
      EdmUtils.updateDocStatus(ID, NetID, 102, 'Downloading;正在下载中')
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          EdmUtils.updateDocStatus(ID, NetID, 200, 'Download complete;下载完成')
          if (!EdmInObj[ID].cacheDocuments[NetID].ResponseHeader) {
            EdmInObj[ID].cacheDocuments[NetID].ResponseHeader = {}
          }
          EdmInObj[ID].cacheDocuments[NetID].ResponseHeader.checkCode = xhr.getResponseHeader('checkCode')
          EdmInObj[ID].cacheDocuments[NetID].ResponseHeader.ContentSize = xhr.getResponseHeader('Content-Size')
          EdmUtils.DownloadFinish(ID)
          EdmUtils.clearNetworkSpeed(ID)
          EdmUtils.resolveDownloadResponse(
            xhr.response,
            function () {
              EdmUtils.setCompDownload(xhr, ID, 'Batch')
            },
            function (failData) {
              EdmInObj[ID].fail(failData, docs)
            }
          )
          return true
        } else if (xhr.readyState === 4 && xhr.status === 0) {
          if (EdmInObj[ID].cacheDocuments[NetID].abort === true) {
            EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
            return
          }
          if (cnt < EdmUtils.REPLAY_LIMIT) {
            cnt ++
            EdmInObj[ID].batchDownload(docs, NetID, cnt)
          } else {
            EdmUtils.clearNetworkSpeed(ID)
          }
          return false
        } else if (xhr.readyState === 4) {
          EdmInObj[ID].responseErrorResolve(docs, xhr, function () {
            EdmInObj[ID].batchDownload(docs, NetID)
          })
          EdmUtils.clearNetworkSpeed(ID)
          return false
        }
      }
      xhr.send(JSON.stringify(data))
    }
  },
  setClientPackDownload(ID) {
    EdmInObj[ID].clientPackDownload = clientPackDownload
  },
  // 在 url 中插入文档对应的信息
  getSingleDownloadPre: function (ID, doc) {
    var preUrl = EdmInObj[ID].edmServiceDomain + EdmInObj[ID].contextPath.single
    preUrl = preUrl.replace('{docId}', doc.docId)
    preUrl = preUrl.replace('{docVersion}', doc.docVersion)
    preUrl = preUrl.replace('{wmType}', doc.wmType || '')
    preUrl = preUrl.replace('{decryptKey}', doc.decryptKey || '')
    return preUrl
  },
  // 在 url 中拼接时间与用户 Id 字段
  getSingleDownloadUrl: function (preUrl, doc, ID) {
    var url = preUrl + '&_t=' + new Date().getTime()
    if (EdmInObj[ID].userId) {
      var params = {}
      params.userId = EdmInObj[ID].userId
      url = utils.uriParamFormat(url, params)
    }
    return url
  },
  // 设置单文件下载监听函数
  setSingleDownloadListener: function (xhrDownload, ID, NetID) {
    var NetworkSingleFlag = true
    var NetworkSpeed = setInterval(() => {
      NetworkSingleFlag = true
    }, 1000)
    xhrDownload.addEventListener(
      'progress',
      function (event) {
        var total = xhrDownload.getResponseHeader('Content-Size')
        var percent = ((event.loaded / total) * 100).toFixed(2)
        var IDObj = {
          total: total,
          loaded: event.loaded
        }
        if (NetworkSingleFlag === true) {
          EdmUtils.CalculateNetworkSpeed(ID, NetID, IDObj)
          NetworkSingleFlag = false
        }
        if (event.loaded === event.total) {
          clearInterval(NetworkSpeed)
        }

        EdmInObj[ID].handleProgress(percent) // 回调进度
      },
      false
    )
  },
  FirstNetworkSpeed(ID, cacheSign) {
    if (EdmUtils.Firstflag) {
      EdmUtils.Firstflag = false
      EdmUtils.NetSpeedInterval = setInterval(() => {
        EdmInObj[ID].networkSpeed(EdmUtils.NetworkSpeedObject)
        EdmUtils.TemnetworkSpeed = 0
      }, 1000)
    }
  },
  // 计算网速
  CalculateNetworkSpeed(ID, NetID, e) {
    if (EdmUtils.NetworkSpeedObject[NetID]) {
      var lastLoaded = EdmUtils.NetworkSpeedObject[NetID].Nspeed
      if (e.loaded - lastLoaded > 0) {
        EdmUtils.NetworkSpeedObject[NetID].Nspeed = e.loaded - lastLoaded
      }
      EdmUtils.NetworkSpeedObject[NetID].loaded = e.loaded
    } else {
      EdmUtils.NetworkSpeedObject[NetID] = {
        Nspeed: e.loaded,
        loaded: e.loaded,
        total: e.total
      }
    }
    EdmUtils.getTemnetworkSpeed(ID, EdmUtils.NetworkSpeedObject, NetID)
    EdmUtils.FirstNetworkSpeed(ID, NetID)
  },
  getTemnetworkSpeed(ID, NetworkSpeedObject, cacheSign) {
    let { Nspeed } = EdmUtils.NetworkSpeedObject[cacheSign]
    EdmUtils.TemnetworkSpeed += Nspeed
    EdmUtils.NetworkSpeedObject[cacheSign].NetworkSpeed = EdmUtils.TemnetworkSpeed
  },
  // 文档下载完成回调函数
  DownloadFinish: function (ID) {
    if (EdmInObj[ID].finish) {
      EdmInObj[ID].finish(EdmInObj[ID].cacheDocuments)
    }
  },
  // 清除定时器与并发累加
  clearNetworkSpeed: function (ID) {
    clearInterval(EdmUtils.NetSpeedInterval)
    EdmUtils.Firstflag = true
  },
  // 单文件下载查询文档信息
  setQueryingDoc: function (ID) {
    EdmInObj[ID].QueryingDoc = function (docs, NetID, cnt = 0) {
      var url = EdmInObj[ID].edmServiceDomain + EdmInObj[ID].contextPath.querying
      var jsonData = {
        docInfoVO: { ids: [docs[0].docId], docType: docs[0].wmType || '', docVersion: docs[0].docVersion || '' }
      }
      var xhrQuerying = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
      xhrQuerying.open('POST', url, true)
      xhrQuerying.setRequestHeader('Content-Type', 'application/json')
      xhrQuerying.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
      xhrQuerying.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
      if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
        xhrQuerying.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhrQuerying.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasSingle)
        xhrQuerying.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      } else {
        xhrQuerying.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhrQuerying.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      }

      xhrQuerying.onreadystatechange = function (event) {
        if (xhrQuerying.readyState === 4) {
          if (xhrQuerying.status === 200) {
            EdmUtils.SetDownMode(xhrQuerying, ID, docs, NetID)
            return true
          } else if (xhrQuerying.status === 0) {
            if (cnt < EdmUtils.REPLAY_LIMIT) {
              cnt ++
              EdmInObj[ID].QueryingDoc(docs, NetID, cnt)
            } else {
              EdmInObj[ID].fail(EDMDownloadErrorCodes.NET_EXCEPTION, docs)
              return false
            }
            return false
          } else {
            EdmInObj[ID].responseErrorResolve(docs, xhrQuerying, function () {
              EdmInObj[ID].QueryingDoc(docs, NetID)
            })
            return true
          }
        }
      }
      xhrQuerying.send(JSON.stringify(jsonData))
    }
  },
  checkIwmTypeDoc: function (docs, verList) {
    // 处理附属文档类型为iwm的文档
    const docsListByVer = verList.filter((el) => el.version === docs[0].docVersion)[0].docInfo
    const docsListByIwm = docsListByVer.filter((el) => {
      return el.wmType ? el.wmType.toString().toLowerCase() === 'iwm' : false
    })
    const docsListByWm = docsListByVer.filter((el) => {
      return el.wmType ? el.wmType.toString().toLowerCase() === 'wm' : false
    })
    let wmType
    if (docsListByIwm[0]) {
      wmType = docsListByIwm[0].wmType
    } else if (docsListByWm[0]) {
      wmType = docsListByWm[0].wmType
    }
    return wmType
  },
  SetCheckIwmType: function (docs, response) {
    if (docs[0].iwmType) {
      const verList = response.result.outDocQueryList[0].verInfo
      const wmType = EdmUtils.checkIwmTypeDoc(docs, verList)
      if (wmType) {
        docs[0].wmType = wmType
      } else {
        docs = []
      }
    }
  },
  // 下载模式判断。根据请求返回的文档大小判断是直接下载还是分块下载
  SetDownMode: function (xhrQuerying, ID, docs, NetID) {
    EdmInObj[ID].downloadComplete = localStorage.getItem('downloadComplete') || "true"
    localStorage.setItem('downloadComplete', "false")
    var response = utils.isEmpty(xhrQuerying.response) ? {} : JSON.parse(xhrQuerying.response)
    try {
      if (
        response.result &&
        response.result.outDocQueryList.length > 0 &&
        response.result.outDocQueryList[0].verInfo[0]
      ) {
        let maxVersionNum = 1
        let theFile = response.result.outDocQueryList[0].verInfo[0].docInfo[0]
        theFile.wmType = theFile.docType
        for (let i = 0; i < response.result.outDocQueryList[0].verInfo.length; i++) {
          const element = response.result.outDocQueryList[0].verInfo[i]
          const VerNum = element.version.substr(1, element.version.length - 1) * 1
          if (VerNum > maxVersionNum) {
            theFile = element.docInfo[0]
            maxVersionNum = VerNum
          }
        }
        docs[0].fileSize = theFile.fileSize
        docs[0].docName = theFile.docName
        EdmUtils.SetCheckIwmType(docs, response)
        EdmUtils.setChunkConfig(ID, response.result.outDocQueryList[0])
        if (
          (EdmInObj[ID].chunkAtom &&
            theFile.fileSize > EdmUtils.CHUNK_ATOM * 1024 * 1024 &&
            theFile.fileSize > EdmUtils.CHUNK_LIMIT * 1024 * 1024) ||
          (!EdmInObj[ID].chunkAtom && theFile.fileSize > EdmUtils.CHUNK_LIMIT * 1024 * 1024)
        ) {
          IDB.openDB('edmDB', 'edmDownload').then((db) => {
            EdmInObj[ID].db = db
            EdmUtils.initConfiguration(ID, NetID)
            EdmInObj[ID].BasedDownload(docs[0], NetID, theFile)
          })
        } else {
          EdmInObj[ID].singleDownload(docs, NetID)
        }
      } else if (
        response.result &&
        response.result.outDocQueryList.length > 0 &&
        !response.result.outDocQueryList[0].verInfo[0]
      ) {
        EdmInObj[ID].fail(EDMDownloadErrorCodes.NOT_DOWNLOAD_PERMISSION, docs)
      } else {
        EdmInObj[ID].fail({ status: response.status, message: response.message }, docs)
      }
    } catch (error) {
      window.edmError = error
      EdmInObj[ID].fail(EDMDownloadErrorCodes.DOES_NOT_EXIST, docs)
    }
  },
  shardGroup: function (ID, map, doc, NetID, theFile, chunkAtomSize, floor, remainder, list) {
    EdmInObj[ID].downloadedStream = map
    if (EdmInObj[ID].downloadedStream.size) {
      EdmInObj[ID].resumableDownload = true
    }
    for (let index = 0; index < floor + 1; index++) {
      const key = theFile.docId + '_' + theFile.docVersion + '_' + theFile.wmType + '_' + index
      if (EdmInObj[ID].downloadedStream.has(key)) {
        EdmInObj[ID].writerFile.set(index, EdmInObj[ID].downloadedStream.get(key).fileStream)
      }
      var num = index + 1
      var obj = {
        index: index,
        chunkNum: num,
        file: null,
        Progress: 0,
        startRange: index === 0 ? 0 : index * chunkAtomSize, // 分块开始的大小
        endRange: num * chunkAtomSize - 1, // 分块结束的大小
        complete: false
      }
      if (index !== floor) {
        list.push(obj)
      } else if (remainder !== 0) {
        var num = floor + 1
        var obj = {
          index: index,
          chunkNum: num,
          Progress: 0,
          file: null,
          startRange: (num - 1) * chunkAtomSize, // 分块开始的大小
          endRange: (num - 1) * chunkAtomSize + remainder, // 分开结束的大小
          complete: false
        }
        list.push(obj)
      }
    }
    EdmInObj[ID].CHUNK_LIST = list
    EdmInObj[ID].CHUNK_NUM = list.length
    EdmInObj[ID].CHUNK_FILENAME = doc.docName
    EdmInObj[ID].cacheDocuments[NetID].xhrMaps = {}
    EdmInObj[ID].QueuingDownload(list, doc, ID, NetID, theFile)
  },
  // 对大文件进行分块下载初始化
  setBasedDownload: function (ID) {
    EdmInObj[ID].BasedDownload = function (doc, NetID, theFile) {
      var fileSize = doc.fileSize
      var chunkAtomSize = EdmUtils.getChunkAtomSize(ID)
      var floor = Math.floor(fileSize / chunkAtomSize)
      var remainder = fileSize - chunkAtomSize * floor
      var list = []
      if (EdmInObj[ID].downloadComplete === "false") {
        IDB.cursorGetData(EdmInObj[ID].db, 'edmDownload').then((dbMap) => {
          EdmUtils.shardGroup(ID, dbMap, doc, NetID, theFile, chunkAtomSize, floor, remainder, list)
        })
      } else {
        EdmUtils.shardGroup(ID, new Map(), doc, NetID, theFile, chunkAtomSize, floor, remainder, list)
      }
    }
  },
  // 为大文件的每个分块设置请求头
  setQueuingDownloadHeader: function (xhrQD, ID) {
    xhrQD.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
    xhrQD.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
    if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
      xhrQD.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
      xhrQD.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      xhrQD.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasSingle)
    } else {
      xhrQD.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
      xhrQD.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
    }
    return xhrQD
  },
  // 创建文件流把下载的文件块写入到磁盘中
  setWriterFile: function (doc, ID, list, NetID, theFile) {
    var fileData = []
    var flag =
      (doc.fileSize > 104857600 &&
        streamSaver &&
        navigator.userAgent.indexOf('Chrome') > 0 &&
        location.protocol === 'https:') ||
      EdmInObj[ID].downloadType === 'plugin'
    if (!('serviceWorker' in navigator)) {
      flag = false
    }
    if (flag) {
      EdmInObj[ID].fileStream = streamSaver.createWriteStream(EdmInObj[ID].CHUNK_FILENAME, { size: doc.fileSize })
      EdmInObj[ID].writer = EdmInObj[ID].fileStream.getWriter() // 开始写入
    }
    var CHUNK_INDEX = 0
    var writerStreamSaver = () => {
      if (EdmInObj[ID].writerFile.get(CHUNK_INDEX)) {
        if (flag) {
          const fr = new FileReader()
          fr.readAsArrayBuffer(EdmInObj[ID].writerFile.get(CHUNK_INDEX))
          fr.onload = function () {
            EdmInObj[ID].writer.write(new Uint8Array(fr.result)).then(() => {
              EdmInObj[ID].writerFile.delete(CHUNK_INDEX)
              var percent = Math.trunc(((CHUNK_INDEX + 1) / EdmInObj[ID].CHUNK_NUM) * 100 * 100) / 100
              EdmInObj[ID].handleProgress(percent) // 回调进度
              if (CHUNK_INDEX + 1 === EdmInObj[ID].CHUNK_NUM) {
                EdmInObj[ID].MergeFiles(ID, NetID, doc)
                EdmInObj[ID].writer.close()
                EdmInObj[ID].fileStream = null
                EdmInObj[ID].writer = null
                localStorage.removeItem('downloadComplete')
                IDB.deleteAllDate(EdmInObj[ID].db, 'edmDownload', theFile).then(() => {
                  IDB.deleteDBAll('edmDownload')
                })
              } else {
                CHUNK_INDEX++
                writerStreamSaver(CHUNK_INDEX)
              }
            })
          }
          fr.onerror = function (error) {
            if (error.currentTarget.error.name === 'NotReadableError') {
              EdmInObj[ID].fail(EDMDownloadErrorCodes.CACHE_FULL)
            } else if (error.type === 'error') {
              EdmInObj[ID].fail(EDMDownloadErrorCodes.DOWNLOAD_ERROR)
            }
            EdmInObj[ID].netExceptionFlag = true
            EdmInObj[ID].fileStream ? EdmInObj[ID].fileStream.abort() : EdmInObj[ID].fileStream = null
            EdmInObj[ID].writer ? EdmInObj[ID].writer.abort() : EdmInObj[ID].writer = null
            EdmInObj[ID].fileStream = null
            EdmInObj[ID].writer = null
            EdmInObj[ID].CHUNK_DONE = EdmInObj[ID].CHUNK_NUM
          }
        } else {
          fileData.push(EdmInObj[ID].writerFile.get(CHUNK_INDEX))
          EdmInObj[ID].handleProgress(Math.trunc(((CHUNK_INDEX + 1) / EdmInObj[ID].CHUNK_NUM) * 100 * 100) / 100) // 回调进度
          if (CHUNK_INDEX + 1 === EdmInObj[ID].CHUNK_NUM) {
            EdmInObj[ID].MergeFiles(ID, NetID, doc)
            let blob = ''
            if (EdmInObj[ID].CHUNK_FILENAME.indexOf('.') > -1) {
              blob = new Blob(fileData)
            } else {
              blob = new Blob(fileData, { type: EdmInObj[ID].contentType })
            }
            localStorage.removeItem('downloadComplete')
            IDB.deleteAllDate(EdmInObj[ID].db, 'edmDownload', theFile).then(() => {
              IDB.closeDB(EdmInObj[ID].db)
              var URL = window.URL || window.webkitURL || window.mozURL
              var link = URL.createObjectURL(blob)
              var a = document.createElement('a')
              a.href = link
              a.download = EdmInObj[ID].CHUNK_FILENAME
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              URL.revokeObjectURL(link)
            })
          } else {
            CHUNK_INDEX++
            writerStreamSaver(CHUNK_INDEX)
          }
          EdmInObj[ID].writerFile.delete(CHUNK_INDEX)
        }
      } else if (list[CHUNK_INDEX]) {
        setTimeout(() => {
          writerStreamSaver()
        }, 1000)
      }
    }
    writerStreamSaver()
  },
  setQueuingDownloadFail: function (ID) {
    EdmInObj[ID].QueuingDownloadFail = function (xhrQD, contTpeFlag, ID, doc, flag) {
      if (xhrQD.readyState === 4 && xhrQD.status === 200 && !contTpeFlag && xhrQD.response !== null) {
        const reader = new FileReader()
        let response = xhrQD.response
        if (flag) {
          response = new Blob([response])
        }
        reader.readAsText(response, 'utf-8')
        reader.onload = (e) => {
          const res = JSON.parse(e.target.result)
          if (EdmInObj[ID].FirstPiece) {
            EdmInObj[ID].fail(res, doc)
            EdmInObj[ID].FirstPiece = false
          }
        }
        return false
      }
      return true
    }
  },
  responseResolve: function (ID, xhrQD, paramObj, cnt) {
    if (xhrQD.readyState === 4 && (xhrQD.status === 401 || xhrQD.status === 403)) {
      EdmInObj[ID].responseErrorResolve([paramObj.doc], xhrQD, function () {
        setTimeout(() => {
          paramObj.recursion(paramObj.params.index)
        }, 100)
      })
    } else if (xhrQD.readyState === 4 && xhrQD.status === 0) {
      if (EdmInObj[ID].cacheDocuments[paramObj.NetID].abort) {
        return false
      }
      if (cnt < EdmUtils.REPLAY_LIMIT) {
        cnt ++
        paramObj.recursion(paramObj.params.index, cnt)
      } else {
        if (EdmInObj[ID].failed) {
          return 
        }
        EdmInObj[ID].failed = true
        EdmInObj[ID].cacheDocuments[paramObj.NetID].networkSpeed = 0
        clearInterval(EdmInObj[ID].NetSpeedInterval)
        if (!utils.getOnLine()) {
          EdmInObj[ID].fail(EDMDownloadErrorCodes.NET_EXCEPTION, paramObj.doc)
        } else {
          EdmInObj[ID].fail(EDMDownloadErrorCodes.CACHE_FULL, paramObj.doc)
        }
        EdmInObj[ID].netExceptionFlag = true
        EdmInObj[ID].fileStream ? EdmInObj[ID].fileStream.abort() : EdmInObj[ID].fileStream = null
        EdmInObj[ID].writer ? EdmInObj[ID].writer.abort() : EdmInObj[ID].writer = null
        EdmInObj[ID].fileStream = null
        EdmInObj[ID].writer = null
      }
      return false
    }
  },
  // 大文件分块下载请求回调
  setQueuingDownloadXhh: function (ID) {
    EdmInObj[ID].QueuingDownloadXhh = function (xhrQD, paramObj, ID, theFile, cnt) {
      var contTpeFlag = xhrQD.getResponseHeader('Content-Type') !== 'application/json;charset=UTF-8'
      let downloadFailFlag = EdmInObj[ID].QueuingDownloadFail(xhrQD, contTpeFlag, ID, paramObj.doc, paramObj.flag)
      if (!downloadFailFlag) {
        return false
      }
      if (xhrQD.readyState === 4 && xhrQD.status === 200 && contTpeFlag) {
        if (paramObj.params.index === 0 || EdmInObj[ID].resumableDownload) {
          EdmInObj[ID].CHUNK_FILENAME = EdmUtils.responseFileName(
            xhrQD.getResponseHeader('content-disposition'),
            'UnKnow Name.zip'
          )
          EdmInObj[ID].contentType = xhrQD.getResponseHeader('Content-Type')
          EdmInObj[ID].resumableDownload = false
          EdmUtils.setWriterFile(paramObj.doc, ID, paramObj.list, paramObj.NetID, theFile)
        }
        EdmInObj[ID].recursionNum++
        EdmInObj[ID].CHUNK_DONE++
        let responseData = xhrQD.response
        if (!(responseData instanceof Blob)) {
          responseData = new Blob([responseData])
        }
        EdmInObj[ID].writerFile.set(paramObj.params.index, responseData)
        const key = `${theFile.docId}_${theFile.docVersion}_${theFile.wmType}_${paramObj.params.index}`
        IDB.addData(EdmInObj[ID].db, 'edmDownload', { key: key, fileStream: responseData, saveTime: new Date().valueOf() })
        if (EdmInObj[ID].CHUNK_DONE === EdmInObj[ID].CHUNK_NUM) {
          EdmInObj[ID].cacheDocuments[paramObj.NetID].ResponseHeader = {
            checkCode: xhrQD.getResponseHeader('checkCode'),
            ContentSize: paramObj.doc.fileSize
          }
          EdmInObj[ID].cacheDocuments[paramObj.NetID].networkSpeed = 0
          EdmUtils.clearNetworkSpeed(ID)
        } else if (
          EdmInObj[ID].CHUNK_DONE < EdmInObj[ID].CHUNK_NUM &&
          EdmInObj[ID].recursionNum - 4 < paramObj.list.length
        ) {
          paramObj.recursion(EdmInObj[ID].recursionNum)
        }
        return true
      } else {
        EdmUtils.responseResolve(ID, xhrQD, paramObj, cnt)
      }
      delete EdmInObj[ID].cacheDocuments[paramObj.NetID].xhrMaps[paramObj.params.chunkNum]
    }
  },
  // 发送 ajax 请求，进行大文件的分块下载
  setQueuingDownload: function (ID) {
    EdmInObj[ID].QueuingDownload = function (list, doc, ID, NetID, theFile) {
      EdmUtils.stopDonwload(ID, NetID)
      var flag =
        (doc.fileSize > 104857600 &&
          streamSaver &&
          navigator.userAgent.indexOf('Chrome') > 0 &&
          location.protocol === 'https:') ||
        EdmInObj[ID].downloadType === 'plugin'
      var preUrl = EdmUtils.getSingleDownloadPre(ID, doc)
      var url = EdmUtils.getSingleDownloadUrl(preUrl, doc, ID)
      EdmInObj[ID].recursionNum = EdmUtils.getLength(list, ID) - 1 // 当前下载文件的索引
      EdmUtils.updateDocStatus(ID, NetID, 106, 'Block downloading;分块下载中')
      var xhrQueuingDownloadGET = function (url, params, cnt) {
        EdmInObj[ID].cacheDocuments[NetID].xhrMaps[params.chunkNum] = utils.newXMLHttpRequest(
          EdmInObj[ID].withCredentials
        )
        var xhrQD = EdmInObj[ID].cacheDocuments[NetID].xhrMaps[params.chunkNum]
        xhrQD.open('GET', url, true)
        xhrQD.responseType = flag ? 'arraybuffer' : 'blob'
        xhrQD = EdmUtils.setQueuingDownloadHeader(xhrQD, ID)
        EdmUtils.blockSpeed(xhrQD, ID, NetID)
        if (doc.fileName && typeof doc.fileName === 'string') {
          if (!EdmInObj[ID].checkPoint(doc.fileName)) {
            return
          }
          xhrQD.setRequestHeader('fileName', encodeURIComponent(doc.fileName))
          doc.docName = doc.fileName
        }
        const paramObj = {
          params: params,
          doc: doc,
          list: list,
          flag: flag,
          NetID: NetID,
          recursion: recursion
        }
        xhrQD.onreadystatechange = function (event) {
          EdmInObj[ID].QueuingDownloadXhh(xhrQD, paramObj, ID, theFile, cnt)
        }
        if (EdmInObj[ID].netExceptionFlag) {
          return false
        }
        xhrQD.send()
      }
      var recursion = (i, cnt = 0) => {
        const key = theFile.docId + '_' + theFile.docVersion + '_' + theFile.wmType + '_' + i
        if (!EdmInObj[ID].downloadedStream.has(key)) {
          if (list[i] && list[i].file === null) xhrQueuingDownloadGET(utils.uriParamFormat(url, list[i]), list[i], cnt)
        } else {
          EdmInObj[ID].recursionNum++
          EdmInObj[ID].CHUNK_DONE++
          if (EdmInObj[ID].CHUNK_DONE === EdmInObj[ID].CHUNK_NUM) {
            EdmInObj[ID].MergeFiles(ID, NetID, doc)
            EdmInObj[ID].cacheDocuments[NetID].networkSpeed = 0
            EdmUtils.clearNetworkSpeed(ID)
          } else if (EdmInObj[ID].CHUNK_DONE < EdmInObj[ID].CHUNK_NUM && EdmInObj[ID].recursionNum - 4 < list.length) {
            recursion(EdmInObj[ID].recursionNum)
          }
        }
      }
      for (var index = 0; index < EdmUtils.getLength(list, ID); index++) {
        recursion(index)
      }
    }
  },
  // 分块下载计算网速
  blockSpeed: function name(xhr, ID, NetID) {
    var NetworkFlag = true
    var NetworkSpeed = setInterval(() => {
      NetworkFlag = true
    }, 1000)
    xhr.addEventListener(
      'progress',
      function (event) {
        var total = xhr.getResponseHeader('Content-Size')
        var NetIDObj = {
          total: total,
          loaded: event.loaded
        }
        if (NetworkFlag === true) {
          EdmUtils.CalculateNetworkSpeed(ID, NetID, NetIDObj)
          NetworkFlag = false
        }
        if (event.loaded === event.total) {
          clearInterval(NetworkSpeed)
        }
      },
      false
    )
  },
  // 获取列表长度
  getLength: function (list, ID) {
    var length = list.length
    let limit = EdmUtils.getChunkGroupSize(ID)
    if (list.length > limit) {
      length = limit
    }
    return length
  },
  // 文件合并
  setMergeFiles: function (ID) {
    EdmInObj[ID].MergeFiles = function (ID, NetID, doc) {
      EdmUtils.updateDocStatus(ID, NetID, 107, 'In Merge Files;合并文件中')
      EdmUtils.updateDocStatus(ID, NetID, 200, 'Download succeeded.;下载完成')
      EdmUtils.DownloadFinish(ID)
    }
  },
  // 调用 resolveDownloadResponse 方法，根据响应状态对文件进行处理
  SetResolveDownloadResponse: function (ID, NetID, xhrDownload, docs) {
    EdmUtils.resolveDownloadResponse(
      xhrDownload.response,
      function () {
        if (docs.length === 1 && docs[0].fileSize === 0) {
          EdmInObj[ID].handleProgress(100)
        }
        EdmUtils.updateDocStatus(ID, NetID, 200, 'Download succeeded.;下载完成')
        EdmUtils.DownloadFinish(ID)
        EdmUtils.setCompDownload(xhrDownload, ID)
      },
      function (failData) {
        EdmUtils.updateDocStatus(
          ID,
          NetID,
          100,
          'The file name cannot contain special characters \\/:*?"<>|\'.;文件名不能包含特殊符号\\/:*?"<>|\''
        )
        EdmInObj[ID].fail(failData, docs)
      },
      EdmInObj[ID],
      docs
    )
  },
  setCheckPoint: function (ID) {
    EdmInObj[ID].checkPoint = function (fileName) {
      if (fileName[0] === '.') {
        EdmInObj[ID].fail(EDMDownloadErrorCodes.FILENAME_ERROR)
        return false
      } else {
        return true
      }
    }
  },
  setSingleDownload: function (ID) {
    EdmInObj[ID].singleDownload = function (docs, NetID, cnt = 0) {
      var doc = docs[0]
      var preUrl = EdmUtils.getSingleDownloadPre(ID, doc)
      var url = EdmUtils.getSingleDownloadUrl(preUrl, doc, ID)
      EdmInObj[ID].cacheDocuments[NetID].xhr = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
      var xhrDownload = EdmInObj[ID].cacheDocuments[NetID].xhr
      EdmUtils.setSingleDownloadListener(EdmInObj[ID].cacheDocuments[NetID].xhr, ID, NetID)
      xhrDownload.open('GET', url, true)
      xhrDownload.responseType = 'blob'
      xhrDownload.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
      xhrDownload.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
      if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
        xhrDownload.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhrDownload.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
        xhrDownload.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasSingle)
      } else {
        xhrDownload.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
        xhrDownload.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
      }
      if (doc.fileName && typeof doc.fileName === 'string') {
        if (!EdmInObj[ID].checkPoint(doc.fileName)) {
          return
        }
        xhrDownload.setRequestHeader('fileName', encodeURIComponent(doc.fileName))
      }
      EdmUtils.updateDocStatus(ID, NetID, 102, 'Downloading;正在下载中')
      xhrDownload.onreadystatechange = function (event) {
        if (xhrDownload.status === 200 && xhrDownload.readyState === 4) {
          if (!EdmInObj[ID].cacheDocuments[NetID].ResponseHeader) {
            EdmInObj[ID].cacheDocuments[NetID].ResponseHeader = {}
          }
          EdmInObj[ID].cacheDocuments[NetID].ResponseHeader.checkCode = xhrDownload.getResponseHeader('checkCode')
          EdmInObj[ID].cacheDocuments[NetID].ResponseHeader.ContentSize = xhrDownload.getResponseHeader('Content-Size')
          EdmUtils.SetResolveDownloadResponse(ID, NetID, xhrDownload, docs)
          EdmInObj[ID].cacheDocuments[NetID].networkSpeed = 0
          EdmUtils.clearNetworkSpeed(ID)
          return true
        } else if (xhrDownload.status === 0 && xhrDownload.readyState === 4) {
          if (EdmInObj[ID].cacheDocuments[NetID].abort === true) {
            EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
            EdmUtils.clearNetworkSpeed(ID)
            return true
          }
          if (cnt < EdmUtils.REPLAY_LIMIT) {
            cnt ++
            EdmInObj[ID].singleDownload(docs, NetID, cnt)
          } else {
            EdmInObj[ID].cacheDocuments[NetID].networkSpeed = 0
            clearInterval(EdmInObj[ID].NetSpeedInterval)
            EdmInObj[ID].fail(EDMDownloadErrorCodes.CACHE_FULL, docs)
            return false
          }
        } else if (xhrDownload.readyState === 4) {
          EdmInObj[ID].responseErrorResolve(docs, xhrDownload, function () {
            EdmInObj[ID].singleDownload(docs, NetID)
          })
          return false
        }
      }
      xhrDownload.send()
    }
  },
  // 默认网关
  defaultNetwork: function (ID) {
    EdmInObj[ID].network = EdmInObj[ID].network || 'internal'
  },
  // 根据响应状态对文件进行处理
  resolveDownloadResponse: function (response, callback, fail, instance, docs) {
    var type = response.type
    if (type === 'application/json') {
      var reader = new FileReader()
      reader.readAsText(response, 'utf-8')
      reader.onload = function (e) {
        var readerRes = reader.result
        var readerObj = JSON.parse(readerRes)
        if (readerObj.status === 302 && instance && readerObj.result !== null) {
          var confirmEnding = function (str, target) {
            var start = str.length - target.length
            var arr = str.substr(start, target.length)
            if (arr === target) {
              return true
            }
            return false
          }
          var siteDomain = readerObj.result.siteDomain
          if (confirmEnding(siteDomain, '/edm/') || confirmEnding(siteDomain, '/edm')) {
            siteDomain = siteDomain
          } else {
            siteDomain += '/edm'
          }
          instance.edmServiceDomain = siteDomain
          instance.download(docs)
        } else if(readerObj.status === 12096) {
          fail(EDMDownloadErrorCodes.ZERO_SIZE_FILE)
        } else {
          fail(readerObj)
        }
      }
    } else {
      callback()
    }
  },
  // 获取下载文件名
  responseFileName: function (disposition, defaultName) {
    var name = defaultName || 'Unknown Name'
    if (!utils.isEmpty(disposition)) {
      var index = disposition.indexOf('fileName=')
      var replace = 'fileName='
      if (index === -1) {
        replace = "fileName*=UTF-8''"
        index = disposition.indexOf("fileName*=UTF-8''")
        if (index === -1) {
          replace = "filename*=UTF-8''"
          index = disposition.indexOf("filename*=UTF-8''")
        }
      }
      name = disposition.substring(index, disposition.length).replace(replace, '')
    }
    return decodeURIComponent(name)
  },
  // 更新文档下载状态
  updateDocStatus: function (ID, cacheSign, statusCode, message) {
    EdmInObj[ID].cacheDocuments[cacheSign].status = {
      statusCode: statusCode,
      message: message
    }
    EdmInObj[ID].downloadStatus(EdmInObj[ID].cacheDocuments)
  },
  // 获取分块下载数量
  getChunkAtom: function (atom) {
    if (atom) {
      // 用户自定义分片上传文件大小限制
      EdmUtils.CHUNK_ATOM = atom
      if (EdmUtils.CHUNK_ATOM - 2 < 0) {
        EdmUtils.CHUNK_ATOM = 2
      } else if (4 > EdmUtils.CHUNK_ATOM && EdmUtils.CHUNK_ATOM >= 2) {
        EdmUtils.CHUNK_ATOM = 2
      } else if (8 > EdmUtils.CHUNK_ATOM && EdmUtils.CHUNK_ATOM >= 4) {
        EdmUtils.CHUNK_ATOM = 4
      } else if (16 > EdmUtils.CHUNK_ATOM && EdmUtils.CHUNK_ATOM >= 8) {
        EdmUtils.CHUNK_ATOM = 8
      } else if (32 > EdmUtils.CHUNK_ATOM && EdmUtils.CHUNK_ATOM >= 16) {
        EdmUtils.CHUNK_ATOM = 16
      } else if (64 > EdmUtils.CHUNK_ATOM && EdmUtils.CHUNK_ATOM >= 32) {
        EdmUtils.CHUNK_ATOM = 32
      } else if (EdmUtils.CHUNK_ATOM - 64 > 0) {
        EdmUtils.CHUNK_ATOM = 64
      }
    }
  },
  /**
   * 根据数据字典配置设置分片大小和并发数
   * @param {string} id 下载实例ID
   * @param {object} data 响应返回的分片信息
   */
  setChunkConfig: function (id, data) {
    let { downloadChunkSize, downloadChunkGroupSize } = data
    EdmInObj[id].downloadChunkConfig = {}

    if (utils.isNumeric(downloadChunkSize)) {
      EdmInObj[id].downloadChunkConfig.downloadChunkSize = downloadChunkSize
    }

    if (utils.isNumeric(downloadChunkGroupSize)) {
      EdmInObj[id].downloadChunkConfig.downloadChunkGroupSize = downloadChunkGroupSize
    }
  },
  /**
   * 获取分片大小
   * @param {string} id 下载实例ID
   * @returns {number} 分片大小
   */
  getChunkAtomSize(id) {
    let config = EdmInObj[id].downloadChunkConfig || {}

    if (Object.prototype.hasOwnProperty.call(config, 'downloadChunkSize')) {
      return config.downloadChunkSize * 1024 * 1024
    }

    return EdmUtils.CHUNK_ATOM * 1024 * 1024
  },

  /**
   * 获取并发数
   * @param {string} id 下载实例ID
   * @returns {number} 并发数
   */
  getChunkGroupSize(id) {
    let config = EdmInObj[id].downloadChunkConfig || {}

    if (Object.prototype.hasOwnProperty.call(config, 'downloadChunkGroupSize')) {
      return config.downloadChunkGroupSize
    }

    return EdmUtils.CHUNK_BATCH_LIMIT
  },
  // 分块下载数据初始化
  initConfiguration: function (ID) {
    // 分块下载初始化
    EdmInObj[ID].CHUNK_LIST = []
    // 分块下载分块的数量
    EdmInObj[ID].CHUNK_NUM = 0
    // 当前动作完成下载的数量
    EdmInObj[ID].CHUNK_DONE = 0 // 完成分块下载的数量
    EdmInObj[ID].writerFile = new Map()
    EdmInObj[ID].CHUNK_FILENAME = 'UnKnowName.zip'
    EdmInObj[ID].PROGRESS = 0
    // 当前批次
    EdmInObj[ID].QUEUING = 0
    // 需要分为多少批
    EdmInObj[ID].batchQUEUING = 0
    EdmInObj[ID].FirstPiece = true
    EdmUtils.errIndex = 0
    EdmUtils.RequestStatus = 'Requesting'
    // 网速状态列表
    EdmUtils.NetworkSpeedObject = {}
    EdmUtils.asyncCntSuccess = 0
    EdmUtils.asyncIndex = -1
  },
  checkEnableClientPack(ID) {
    return new Promise((resolve, reject)=> {
      if (location.protocol === "http:") {
        return resolve(false)
      }
      if (EdmUtils.clientPackFlag === undefined) { // 只加载时查询一遍字典配置，不每次下载实时查询
        EdmUtils.clientPackFlag = false // 默认不开启
        const url = EdmUtils.getBatchDownloadUrl(ID, 'clientPackSwitch')
        const xhr = utils.newXMLHttpRequest(EdmInObj[ID].withCredentials)
        xhr.open("GET", url, true)
        xhr.setRequestHeader(EdmUtils.HEADERS.APP_ID, EdmInObj[ID].appId)
        xhr.setRequestHeader(EdmUtils.HEADERS.TRACE_ID, EdmInObj[ID].requestTrace + Date.now())
        if (EdmInObj[ID].enterpriseDocumentToken.indexOf('Basic') === 0) {
          xhr.setRequestHeader(EdmUtils.HEADERS.OLD_TOKEN, EdmInObj[ID].enterpriseDocumentToken)
          xhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
          xhr.setRequestHeader(EdmUtils.HEADERS.API_ALIAS, EdmUtils.apiParams.apiAliasSingle)
        } else {
          xhr.setRequestHeader(EdmUtils.HEADERS.TOKEN, EdmInObj[ID].enterpriseDocumentToken)
          xhr.setRequestHeader('x-csrf-token', EdmInObj[ID].xCsrfToken)
        }
        xhr.onreadystatechange = ()=> {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              try {
                let data = JSON.parse(xhr.responseText)
                if (data.status === 200) {
                  EdmUtils.clientPackFlag = data.result
                }
              } catch(err) {
                // do nothing
              } finally {
                resolve(EdmUtils.clientPackFlag)
              }
            } else {
              resolve(EdmUtils.clientPackFlag)
            }
          }
        }
        xhr.send()
      } else {
        resolve(EdmUtils.clientPackFlag)
      }
    })
  }
}

var EdmInObjInstance = function (options) {
  var ID = utils.getRandomValue()
  EdmInObj[ID] = options
  if (EdmUtils.isEmptyOp(ID) || EdmUtils.isEmptyAppId(ID)) {
    return
  }
  EdmUtils.defaultNetwork(ID)
  EdmUtils.setFail(ID)
  EdmUtils.setNetworkSpeed(ID)
  EdmUtils.setServiceDomain(ID)
  EdmUtils.setResponseErrorResolve(ID)
  EdmUtils.setRequestEdmToken(ID)
  EdmUtils.setVerify(ID)
  EdmUtils.setHandleProgress(ID)
  EdmUtils.setBatchDownload(ID)
  EdmUtils.setClientPackDownload(ID)
  EdmUtils.setSingleDownload(ID)
  EdmUtils.setQueryingDoc(ID)
  EdmUtils.setBasedDownload(ID)
  EdmUtils.setQueuingDownload(ID)
  EdmUtils.setMergeFiles(ID)
  EdmUtils.setStatusCallback(ID)
  EdmUtils.setCheckPoint(ID)
  EdmUtils.clearNetworkSpeed(ID)
  EdmUtils.setQueuingDownloadFail(ID)
  EdmUtils.setQueuingDownloadXhh(ID)
  EdmUtils.setAsyncDownloadPackages(ID)
  EdmUtils.setAsyncDownloadLink(ID)
  EdmUtils.setAsyncDownloadFail(ID)
  EdmInObj[ID].cacheDocuments = {}
  return ID
}

// 下载文件的构造函数 batchDownload 为批量下载，QueryingDoc 为单文件下载
var EDMDownloadInstance = function (options) {
  var ID = EdmInObjInstance(options)
  EdmInObj[ID].generateToken = options.generateToken
  window.addEventListener('beforeunload', function (e) {
    if (EdmInObj[ID].writer) {
      EdmInObj[ID].writer.abort()
    }
    if (EdmInObj[ID].fileStream) {
      EdmInObj[ID].fileStream.abort()
    }
  });
  this.download = (docs) => {
    var NetID = utils.getRandomValue()
    EdmUtils.initConfiguration(ID)
    EdmInObj[ID].verify(docs).then((result) => {
      if (result) {
        EdmInObj[ID].cacheDocuments[NetID] = { docs: docs }
        EdmUtils.updateDocStatus(ID, NetID, 101, 'Ready to download;准备下载中')
        if (docs.length === 1) {
          EdmInObj[ID].QueryingDoc(docs, NetID)
        } else if (EdmInObj[ID].asyncPackages === true) {
          EdmInObj[ID].asyncDownloadPackages(docs, NetID)
        } else {
          EdmUtils.checkEnableClientPack(ID).then((enable)=> {
            if (enable) {
              EdmInObj[ID].clientPackDownload(docs, EdmInObj[ID], EdmUtils, ID, NetID)
            } else {
              EdmInObj[ID].batchDownload(docs, NetID)
            }
          })
        }
      }
    })
  }
  this.abortDownload = (NetID) => {
    EdmInObj[ID].cacheDocuments[NetID].abort = true
    EdmUtils.updateDocStatus(ID, NetID, 0, 'The download is canceled,下载被取消')
    if (EdmInObj[ID].cacheDocuments[NetID].docs.length === 1) {
      if (EdmInObj[ID].cacheDocuments[NetID].xhrMaps) {
        for (const k in EdmInObj[ID].cacheDocuments[NetID].xhrMaps) {
          if (Object.hasOwnProperty.call(EdmInObj[ID].cacheDocuments[NetID].xhrMaps, k)) {
            const element = EdmInObj[ID].cacheDocuments[NetID].xhrMaps[k]
            element.abort()
          }
        }
      } else {
        EdmInObj[ID].cacheDocuments[NetID].xhr.abort()
      }
    } else {
      // 批量打包下载
      EdmInObj[ID].cacheDocuments[NetID].xhr.abort()
    }
  }
}
window.EDMDownloadInstance = EDMDownloadInstance