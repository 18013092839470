import utils from "../../common/utils.js";
import { setXhrHeader } from "../service.js";
import EDMDownloadErrorCodes from "../errorCodes.js"

// 小文件下载
class SingleFileDownload {
  constructor(file, packInstance) {
    this.file = file;
    this.packInstance = packInstance;
    this.xhr = null;
    this.aborted = false;
    this.tokenRetryTime = 0;
    this.loadSize = 0;
    this.limit = 1;
  }

  getUrl() {
    const doc = this.file;
    const instance = this.packInstance.instance;
    let url = instance.edmServiceDomain + instance.contextPath.single;
    url = url.replace("{docId}", doc.docId);
    url = url.replace("{docVersion}", doc.docVersion);
    url = url.replace("{wmType}", doc.wmType || "");
    url = url.replace("{decryptKey}", doc.decryptKey || "");
    url = url + "&_t=" + new Date().getTime();

    if (instance.userId) {
      var params = {};
      params.userId = instance.userId;
      url = utils.uriParamFormat(url, params);
    }
    return url;
  }

  download() {
    return new Promise((resolve, reject) => {
      const that = this;
      const instance = this.packInstance.instance;
      const EdmUtils = this.packInstance.EdmUtils;
      const doc = this.file;
      const url = this.getUrl();
      this.packInstance.runningCount++;

      const xhr = utils.newXMLHttpRequest(instance.withCredentials);
      xhr.onreadystatechange = function (event) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let type = xhr.response.type
            if (type === "application/octet-stream") {
              resolve(xhr.response);
            } else if (type === "application/json") {
              let reader = new FileReader()
              reader.readAsText(xhr.response, 'utf-8')
              reader.onload = function (e) {
                let response = e.target.result
                let res = JSON.parse(response)
                if (res.status === 12079) { // 外网下载场景
                  reject(EDMDownloadErrorCodes.KIA_SCANNING)
                } else { // 其他未知场景，403无权限场景在query接口查询时已知，下载时无需额外判断
                  if (res.message) {
                    reject({
                      status: res.status,
                      message: res.message
                    })
                  } else {
                    reject(EDMDownloadErrorCodes.DOWNLOAD_ERROR)
                  }
                }
              }
            }
          } else if (xhr.status === 401) { // token过期重试
            that.tokenRetry(resolve, reject)
          } else if (xhr.status === 0) {
            if (that.aborted) {
              reject({
                status: 0,
                message: "取消下载。Cancel Download."
              })
            } else {
              reject(EDMDownloadErrorCodes.CACHE_FULL) // 存储空间已满，浏览器自动取消请求场景
            }
          } else {
            reject(EDMDownloadErrorCodes.DOWNLOAD_ERROR)
          }
        }
      };

      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      setXhrHeader(xhr, EdmUtils, instance, doc)
      
      xhr.addEventListener('progress',(event) => {
        that.updateProgress(event.loaded)
      }, false)

      this.xhr = xhr
      
      xhr.send();
    });
  }

  tokenRetry(resolve, reject) {
    if (this.tokenRetryTime > this.packInstance.EdmUtils.TOKEN_REPLAY_LIMIT) {
      reject(EDMDownloadErrorCodes.TOKEN_INVALID)
    } else {
      this.tokenRetryTime += 1
      this.packInstance.instance.requestEdmToken(true, [this.file]).then((token)=> {
        if (token) {
          this.packInstance.runningCount--;
          resolve(this.download())
        } else {
          this.tokenRetry(resolve, reject)
        }
      })
    }
  }

  updateProgress(loadSize) {
    this.loadSize = loadSize
    this.packInstance.updateProgress()
  }

  abort() {
    this.aborted = true;
    this.xhr.abort()
  }
}

export default SingleFileDownload;
