if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
}

if (typeof String.prototype.endsWith != "function") {
  String.prototype.endsWith = function (str) {
    return this.slice(-str.length) == str;
  };
}
