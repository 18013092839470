import utils from "../../common/utils.js";
import { setXhrHeader } from "../service.js";
import EDMDownloadErrorCodes from "../errorCodes.js"

// 大文件下载
class SliceFileDownload {
  constructor(file, packInstance) {
    this.file = file;
    this.packInstance = packInstance;
    this.runningCount = 0;  // 当前分片请求数
    this.runningXhr = new Map(); // 当前分片请求对象
    this.allXhrSize = new Map();
    this.result = [];
    this.limit = file.downloadChunkGroupSize || 5; // 最大分片并发数
    this.chunkSize = (file.downloadChunkSize || this.packInstance.EdmUtils.CHUNK_ATOM) * 1024 * 1024 // 单个分片尺寸
    this.chunkNum = 0;
    this.maxRetryTime = 3; // 分片失败最大重试次数
    this.sliceList = [];
    this.tokenRetryTime = 0;
    this.loadSize = 0;
    this.aborted = false;
  }

  getUrl(sliceInfo) {
    const doc = this.file;
    const instance = this.packInstance.instance;
    const params = utils.simpleDeepClone(sliceInfo);
    params.file = null;

    let url = instance.edmServiceDomain + instance.contextPath.single;
    url = url.replace("{docId}", doc.docId);
    url = url.replace("{docVersion}", doc.docVersion);
    url = url.replace("{wmType}", doc.wmType || "");
    url = url.replace("{decryptKey}", doc.decryptKey || "");
    url = url + "&_t=" + new Date().getTime();

    if (instance.userId) {
      params.userId = instance.userId;
    }

    url = utils.uriParamFormat(url, params);
    return url;
  }

  // 切片
  slice() {
    const sliceList = [];
    const num = Math.ceil(this.file.fileSize / this.chunkSize);
    this.chunkNum = num

    for (let i = 0; i < num; i++) {
      const splitInfo = {
        index: i,
        chunkNum: i + 1,
        file: this.file,
        Progress: 0,
        startRange: i * this.chunkSize,
        endRange:
          i + 1 === num
            ? this.file.fileSize - 1
            : (i + 1) * this.chunkSize - 1,
        complete: false,
        retryTime: 0,
      };
      sliceList.push(splitInfo);
    }
    return sliceList;
  }

  download() {
    this.sliceList = this.slice()
    let limit = Math.min(this.limit, this.packInstance.maxRunningCount - this.packInstance.runningCount)
    return new Promise((resolve, reject) => {
      for (let i = 0; i < limit; i++) {
        this.concurrentDownload(resolve, reject);
      }
    });
  }

  abort() {
    if (this.aborted) return
    this.aborted = true
    this.runningXhr.forEach((xhr, key)=> {
      xhr.abort()
    })
  }

  // 并发下载
  concurrentDownload(resolve, reject) {
    if (this.runningCount > this.limit || (this.packInstance.maxRunningCount - this.packInstance.runningCount) <= 0 || this.aborted) return;

    const sliceInfo = this.sliceList.shift();

    if (sliceInfo) {
      this.runningCount++;
      this.packInstance.runningCount++;
      this.sliceDownload(sliceInfo)
        .then((data) => {
          this.packInstance.runningCount--;
          this.result.push(data);
        })
        .catch(async(err) => {
          this.packInstance.runningCount--;
          await this.handleErr(err, reject)
        })
        .finally(() => {
          this.runningXhr.delete(sliceInfo.index) // 避免非运行请求取消时也调用abort方法
          this.runningCount--;
          this.updateProgress()
          let limit = Math.min(this.limit, this.packInstance.maxRunningCount - this.packInstance.runningCount)
          for (let i = 0; i < limit; i++) {
            this.concurrentDownload(resolve, reject);
          }
        });
    } else {
      if (this.runningCount === 0 && !this.aborted) {
        this.result.sort((a, b) => {
          return a.index - b.index;
        });
        const blob = new Blob(this.result.map((r) => r.data), { type: "application/octet-stream"} )
        if (blob.size === this.file.fileSize) {
          resolve(blob);
        } else {
          reject(EDMDownloadErrorCodes.FILE_INCOMPLETE)
        }
      }
    }
  }

  // 分片下载
  sliceDownload(sliceInfo) {
    return new Promise((resolve, reject) => {
      const instance = this.packInstance.instance;
      const EdmUtils = this.packInstance.EdmUtils;
      const doc = this.file;
      const url = this.getUrl(sliceInfo);
      const xhr = utils.newXMLHttpRequest(instance.withCredentials);
      
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      setXhrHeader(xhr, EdmUtils, instance, doc)

      xhr.onreadystatechange = function (event) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let type = xhr.response.type
            if (type === "application/octet-stream") {
              resolve({
                index: sliceInfo.index,
                data: xhr.response
              });
            } else if (type === "application/json") {
              let reader = new FileReader()
              reader.readAsText(xhr.response, 'utf-8')
              reader.onload = (e) => {
                reject({
                  type: "JSON",
                  response: JSON.parse(e.target.result)
                })
              }
            }
          } else {
            reject({
              slice: sliceInfo,
              errStatus: xhr.status
            })
          }
        }
      };

      this.runningXhr.set(sliceInfo.index, xhr)

      xhr.addEventListener('progress',(event) => {
        this.allXhrSize.set(sliceInfo.index, event.loaded)
        this.updateProgress()
      }, false)
      xhr.send();
    });
  }

  // 下载异常处理
  async handleErr(err, reject) {
    let { errStatus, slice , type, response} = err

    if (type === "JSON") {
      if (response.status === 12079) { // 外网下载场景
        reject(EDMDownloadErrorCodes.KIA_SCANNING)
      } else { // 其他未知场景，403无权限场景在query接口查询时已知，下载时无需额外判断
        if (response.message) {
          reject({
            status: response.status,
            message: response.message
          })
        } else {
          reject(EDMDownloadErrorCodes.DOWNLOAD_ERROR)
        }
      }
      this.abort() // 非数据类型返回取消后续下载
      return
    }

    if (errStatus === 401) {
      try {
        let tokenFlag = await this.tokenRetry()
        if (tokenFlag) {
          this.sliceList.unshift(slice)
        }
      } catch(err) {
        this.abort()
        reject(err)
      }
    } else if (errStatus === 0) {
      if (this.aborted) {
        reject({
          status: 0,
          message: "取消下载。Cancel Download."
        })
      } else {
        this.abort();
        reject(EDMDownloadErrorCodes.CACHE_FULL)
      }
    } else {
      if (slice.retryTime < this.packInstance.EdmUtils.REPLAY_LIMIT) { // 分片重试
        slice.retryTime++
        this.sliceList.push(slice)
      } else {
        this.abort();
        reject({
          status: 500,
          message: `${slice.index}分片超过最大重试次数，终止${slice.file.docId}下载.The number of segment retries exceeds the maximum. The download is terminated.`
        })
      }
    }
  }

  // token 过期重试
  tokenRetry() {
    return new Promise((resolve, reject)=> {
      if (this.tokenRetryTime > this.packInstance.EdmUtils.TOKEN_REPLAY_LIMIT) {
        reject(EDMDownloadErrorCodes.TOKEN_INVALID)
      } else {
        this.tokenRetryTime += 1
        this.packInstance.instance.requestEdmToken(true, [this.file]).then((token)=> {
          if (token) {
            this.tokenRetryTime = 0;
            resolve(true)
          } else {
            resolve(this.tokenRetry()) // todo 再测试多次token重试场景，递归调用是否可以
          }
        })
      }
    })
  }

  updateProgress() {
    let downloadSize = 0
    this.allXhrSize.forEach((size)=> {
      downloadSize += size
    })
    this.loadSize = downloadSize
    this.packInstance.updateProgress()
  }
}

export default SliceFileDownload;
