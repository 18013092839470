const utils = {
  // 获取随机值
  getRandomValue: function () {
    var array = new Uint32Array(1);
    var crypto = window.crypto || window.msCrypto;
    crypto.getRandomValues(array);
    var randomStr = array[0];
    return "ID" + randomStr;
  },
  // 判断传入的数据是否为空
  isEmpty: function (o) {
    if (o === null || o === undefined) {
      return true;
    }
    var type = typeof o;
    if (type === "string" && o.trim().length < 1) {
      return true;
    }
    if (type === "object" && o.length === undefined) {
      return JSON.stringify(o) === "{}";
    }
    if (type === "object" && o.length !== undefined) {
      return o.length < 1;
    }
    return false;
  },
  /**
   * 获取当前用户网页的协议
   */
  getProtocol: function () {
    if (location.protocol === "https:" || location.protocol === "http:") {
      return location.protocol;
    }
    return "https:";
  },
  // 在 url 中拼接请求字段
  uriParamFormat: function (u, p) {
    if (u.indexOf('?') < 0) {
      u = u + '?'
    } else if (!u.endsWith('&')) {
      u = u + '&'
    }
    if (typeof p === 'object' && JSON.stringify(p) !== '{}' && JSON.stringify(p) !== '[]') {
      for (var key in p) {
        u = u + key + '=' + p[key] + '&'
      }
      u = u.substring(0, u.length - 1)
    }
    return u
  },
  // 拼接 URL
  formatURL: function (q, newurl) {
    if (q !== null && q !== undefined) {
      if (newurl.indexOf('?') < 0) {
        newurl = newurl + '?'
      }
      for (var key in q) {
        if (q[key] !== null && q[key] !== undefined && q[key] !== '') {
          newurl = newurl + key + '=' + q[key] + '&'
        }
      }
    }
    return newurl
  },
  // 把 url 转换为对象
  parseUrlQuery: function (url) {
    var queryObj = {}
    var reg = /[?&]([^=&#]+)=([^&#]*)/g
    var queryMatch = url.match(reg)
    if (queryMatch) {
      for (var i = 0; i < queryMatch.length; i++) {
        let firstEqualSign = queryMatch[i].indexOf('=')
        let key = queryMatch[i].substring(1, firstEqualSign)
        var value = queryMatch[i].substring(firstEqualSign + 1)
        queryObj[key]
          ? (queryObj[key] = [].concat(queryObj[key], value))
          : (queryObj[key] = value)
      }
    }
    return queryObj
  },
  // 设置 EdmToken 请求体参数
  setRequestEdmTokenQueryBody: function (url, params) {
    var queryBody = utils.parseUrlQuery(url)
    for (var key in params) {
      queryBody[key] = params[key]
    }
    return queryBody
  },
  // 创建 Ajax 请求对象
  newXMLHttpRequest: function (credentials) {
    credentials = typeof credentials === 'boolean' ? credentials : true
    var xmlHttp
    // 创建XMLHttpRequest对象
    if (typeof XMLHttpRequest !== 'undefined') {
      try {
        xmlHttp = new XMLHttpRequest()
        xmlHttp.withCredentials = !!credentials
        return xmlHttp
      } catch (e) {
        xmlHttp = false
      }
    }
    // xmlHttp is null
    if (!xmlHttp) {
      try {
        xmlHttp = new ActiveXObject('Msxml2.XMLHTTP')
      } catch (e) {
        // 使用旧版本的IE创建XMLHttpRequest对象
        try {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
        } catch (E) {}
      }
    }
    if ('withCredentials' in xmlHttp) {
      xmlHttp.withCredentials = !!credentials
    } else if (credentials) {
      xmlHttp = new XDomainRequest()
    }
    return xmlHttp
  },
  // 判断值的类型是否为数值
  isNumeric: function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value)
  },
  // 把对象转换为数组
  getValueArr: function (valueObj) {
    var obj = Object.keys(valueObj)
    var valArr = Object.keys(obj).map(function (i) {
      return obj[i]
    })
    return valArr
  },
  getOnLine: function () {
    try {
      const xhrDown = new XMLHttpRequest()
      xhrDown.open('get', `${location.href}`, false)
      xhrDown.send()
      if (xhrDown.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  },
  getTimestamp() {
    var currentDate = new Date();
    let stamp = "_t=" + currentDate.getFullYear() + `${currentDate.getMonth() + 1}` + currentDate.getDate();
    return stamp
  },
  // 获取毫秒级时间戳
  getPreciseTimestamp() {
    const diff = (0 - new Date().getTimezoneOffset()) * 60 * 1000 // 时差转毫秒值
    const timeStamp = new Date(Date.now() + diff).toISOString().replace(/[-T:.Z]/g, "")
    return timeStamp
  },
  simpleDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  /**
   * 统一大写对比字符串值
   * @param {string} str1 
   * @param {string} str2 
   * @returns {boolean}
   */
  upperCompare(str1, str2) {
    return str1.toUpperCase() === str2.toUpperCase()
  }
};

export default utils;
